import React from 'react'
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { action, observable } from 'mobx';
import {observer} from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import {Dialog, DialogTitle, DialogActions, DialogContent, Typography, MenuItem, Button, Select, Grid} from '@material-ui/core';

const styles = theme => ({    
    councillorsSelector: {
        fontSize:'16px',
        width: '100%',
        paddingTop:'15px'
    },
    dialogContainer: {
        alignItems: 'center',
    },
    okButton: {
        margin: '0px 20px',
        width: '6em'
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        lineHeight: '42px',
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        alignItems: 'center',
    },
    content: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal,
        textAlign: 'left',
        alignItems: 'center',
    },
    actionContainer: {
        paddingTop:'12px',
        justifyContent: 'center',
    }
})

@observer
class InitialCouncillorsSelector extends React.Component {

    @observable value = 0;

    valueSelected(newValue) {        
        this.value = newValue;
    }

    @action nextClick() {
        this.props.resultMethod(this.value);
    }

    render() {
        const {classes} = this.props

        return (
            <Dialog open={this.props.open}>
                <Grid container className={classes.dialogContainer}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <DialogTitle>
                            <Typography color={'inherit'} variant={'h4'} className={classes.title}>Set the number of councillors</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography variant='body1' paragraph={true} className={classes.content}>Before you can start building your boundaries, you need to assign how many councillors you think the council should have overall.</Typography>
                            <Typography variant='body1' paragraph={true} className={classes.content}>Start with a number between five (5) and twelve (12). You can change this later if required.</Typography>
                            <Typography variant='body1' paragraph={true} className={classes.content}>To learn more about the number of councillors, please see the submission guide on the VEC website.</Typography>
                            <Select onChange={(event) => {this.valueSelected(event.target.value)}} value={this.value} className={classes.councillorsSelector}
                                inputProps={{name: 'age', id: 'age-simple',}}>
                                <MenuItem value={0}>Number of councillors</MenuItem>
                                <MenuItem value={5}>5 councillors</MenuItem>
                                <MenuItem value={6}>6 councillors</MenuItem>
                                <MenuItem value={7}>7 councillors</MenuItem>
                                <MenuItem value={8}>8 councillors</MenuItem>
                                <MenuItem value={9}>9 councillors</MenuItem>
                                <MenuItem value={10}>10 councillors</MenuItem>
                                <MenuItem value={11}>11 councillors</MenuItem>
                                <MenuItem value={12}>12 councillors</MenuItem>
                            </Select>
                        </DialogContent>
                        <DialogActions className={classes.actionContainer}>
                            <Button variant='contained' color='primary' className={classes.okButton} disabled={this.value === 0} onClick={() => {this.nextClick()}}>OK</Button>
                        </DialogActions>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Dialog>
        )
    }
    
    static propTypes = {
        classes: PropTypes.object.isRequired,
        open: PropTypes.bool.isRequired,
        resultMethod: PropTypes.func.isRequired
    }
}

export default compose(
    withStyles(styles)
)(InitialCouncillorsSelector)