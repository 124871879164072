import { observable, computed, action, runInAction } from "mobx";
import moment from 'moment';
import submissionService from '../services/submission.service';
import { Enums } from '../enums';
import { Messages } from '../messages';
import { Tools } from '../tools';
import WKT from 'terraformer-wkt-parser';

export default class SubmissionStore {
    constructor(sessionStore) {
        this.sessionStore = sessionStore;
        submissionService.setAuthStore(this.sessionStore.authStore);
        this.draftDetails = {};
    }

    @observable hasReadGuidelines = false;
    @observable hasAttendedInfoSession = false;
    @observable wantsToSpeakToSubmission = false;
    @observable hasReadPreliminaryReport = false;
    @observable hasReadAddendumReport = false;
    @observable submissionText = null;
    @observable submissionFiles = [];
    @observable completedWrittenSubmission = false;
    @observable isWrittenSubmissionFormDirty = false;
    @observable submissionMap = null;
    @observable confirmMappingSubmissionDialogOpen = false;
    @observable attachmentsHelperText = '';
    @observable currentSubmissions = [];
    @observable getSubmissionsError = null;
    @observable createSubmissionResult = null;
    @observable createSubmissionError = null;
    @observable submissionCompleteDialogOpen = false;
    @observable deleteDraftError = null;
    @observable submittedDraftId = null;
    @observable getDraftDetailsError = null;
    @observable showSaveDraftMessage = true;
    @observable saveDraftAction = false;
    @observable hasRetrievedSubmissions = false;
    @observable redirectToWrittenSubmission = false;
    @observable isLoadingDrafts = false;
    @observable scrollToSubmissions = false;

    @computed
    get submissionPlainTextLength() {
        return this.submissionText && this.submissionText.length;
        // return this.submissionTextEditorState && this.submissionTextEditorState.getCurrentContent().getPlainText().length;
    }

    @action
    addSubmissionFile(file) {
        this.submissionFiles.push(file);
        this.setIsWrittenSubmissionFormDirty(true);
    }

    @action
    removeSubmissionFile(file) {
        this.setIsWrittenSubmissionFormDirty(true);
        this.submissionFiles = this.submissionFiles.filter(submissionFile => submissionFile !== file);
    }

    @action
    getSubmissions() {
        const { rootStore } = this.sessionStore;
        this.hasRetrievedSubmissions = false;
        rootStore.setBusy(true);
        submissionService.getSubmissions().then((response) => {
            runInAction(() => {
                this.currentSubmissions.replace(response);
                this.hasRetrievedSubmissions = true;
            });
            rootStore.setBusy(false);
        }).catch((error) => {
            rootStore.setBusy(false);
            this.getSubmissionsError = error;
        });
    }

    @action getDraftDetails = (submissionId, loadMapDetails = false) => {
        const { rootStore } = this.sessionStore;

        if(!this.isLoadingDrafts) {
            rootStore.setBusy(true);
            this.isLoadingDrafts = true;

            submissionService.getDraftDetails(submissionId, loadMapDetails)
                .then((response) => {
                    runInAction(() => {
                        this.draftDetails = response;
                    });
                    this.populateDraft(loadMapDetails);
                    rootStore.setBusy(false);
                    this.isLoadingDrafts = false;
                }).catch((error) => {
                    rootStore.setBusy(false);
                    this.getDraftDetailsError = error;
                    this.isLoadingDrafts = false;
                    throw (error);
                });
        }
    }

    @action isValidWrittenSubmission = () => {
        return this.submissionText && this.submissionText.trim().length !== 0;
    }

    @action showWrittenSubmissionValidationError() {
        this.sessionStore.msgBoxStore.show({
            content: Messages.SubmissionWarningMessage,
            header: 'Alert',
            inputOptions: { type: 'NONE' },
            hideCancel: true
        });
    }

    @action validateWrittenAndSave = (draft) => {
        if (this.submissionText && this.submissionText.trim().length !== 0) {
            this.saveSubmission(draft, false, false);
        } else {
            this.showWrittenSubmissionValidationError();
        }
    }

    @action setShowSaveDraftMessage(show) {
        this.showSaveDraftMessage = show;
    }  

    @action saveSubmission = (draft, isRedirectionReq = false, saveOrUpdateMap = true) => {
        const { profileStore, reviewStore } = this.sessionStore;
        const formData = new FormData();
        const existingAttachments = [];

        this.submissionFiles.forEach(file => {
            if (file.attachmentId) {
                // Send the existing attachments separately
                existingAttachments.push(file);
            }
            else {
                // Add new attachments as files
                formData.append(file.name, file);
            }
        });
        

        if (this.draftDetails.submissionId) formData.append('submissionId', this.draftDetails.submissionId);
        formData.append('reviewId', reviewStore.reviewDetails.reviewId);
        formData.append('hasReadSubmissionGuidelines', this.hasReadGuidelines);
        formData.append('hasAttendedPublicInformatioinSession', this.hasAttendedInfoSession);
        formData.append('wantsToSpeakToSubmission', this.wantsToSpeakToSubmission);
        formData.append('submissionText', this.submissionText ? Tools.htmlDecode(this.submissionText) : "");

        if(saveOrUpdateMap){ 
            formData.append('submissionMap', this.submissionMap ? 
                                            JSON.stringify(
                                                this.submissionMap.map(sub => { 
                                                    return { blocks: sub.blocks, properties: sub.properties, geometry: sub.geometry && sub.geometry.coordinates.length > 0 ? 
                                                             WKT.convert(sub.geometry): "" } 
                                                })
                                            ) : 
                                             null);
        }

        formData.append('stage', reviewStore.submissionStage);
        formData.append('profileId', profileStore.userProfile.profileId);
        formData.append('hasReadPreliminaryReport', this.hasReadPreliminaryReport);
        formData.append('hasReadAddendumReport', this.hasReadAddendumReport);
        formData.append('isDraft', !!draft);
        formData.append('attachments', JSON.stringify(existingAttachments));
        this.saveSubmissionFormData(formData, draft, isRedirectionReq);
    }

    @action saveSubmissionFormData = (formData, draft, isRedirectionReq) => {
        const { rootStore, msgBoxStore, routerStore, reviewStore } = this.sessionStore;

        rootStore.setBusy(true);
        submissionService.saveSubmission(formData).then((response) => {
            rootStore.setBusy(false);
            this.setIsWrittenSubmissionFormDirty(false);

            if (draft) {
                this.getSubmissions();
                this.draftDetails.submissionId = response.submissionId;
                //persisting user selected modelling option to show onboarding cards when clicked on "help"
                window.localStorage.setItem(this.draftDetails.submissionId,
                                JSON.stringify({
                                    groundUp : reviewStore.isGroundUpModeSelected, 
                                    existing : reviewStore.isExistingModeSelected
                                }));

                if (this.showSaveDraftMessage) {
                    msgBoxStore.show({
                        content: Messages.SavedSubmissionDraftMessage,
                        header: 'Success',
                        inputOptions: { type: 'NONE' },
                        hideCancel: true,
                        confirmButton: 'Continue'
                    });
                    this.setShowSaveDraftMessage(true);
                }
                if(isRedirectionReq){
                    routerStore.history.push('/writtensubmission/'+this.draftDetails.submissionId);
                }
            } else {
                runInAction(() => {
                    this.createSubmissionResult = response;
                });
                this.createSubmissionError = false;
                window.localStorage.removeItem(this.draftDetails.submissionId);
                this.setSubmissionCompleteDialogOpen(true);
            }
        }).catch((error) => {
            rootStore.setBusy(false);
            this.createSubmissionError = error;
            this.saveDraftAction = draft;
            this.redirectToWrittenSubmission = isRedirectionReq;
            this.setSubmissionCompleteDialogOpen(true);
        });
    }

    @action
    resetSubmissionForm() {
        this.hasReadGuidelines = false;
        this.hasAttendedInfoSession = false;
        this.wantsToSpeakToSubmission = false;
        this.hasReadPreliminaryReport = false;
        this.hasReadAddendumReport = false;
        this.completedWrittenSubmission = false;
        this.submissionText = null;
        this.submissionFiles = [];
        this.submissionMap = null;
        this.attachmentsHelperText = '';
        this.setIsWrittenSubmissionFormDirty(false);
        this.draftDetails = {};
        this.saveDraftAction = false;
        this.redirectToWrittenSubmission = false;
        this.showSaveDraftMessage = true;
    }

    @action
    populateDraft(loadMapDetails) {
        const { modelingMapStore } = this.sessionStore;
        this.hasReadGuidelines = this.draftDetails.hasReadSubmissionGuidelines;
        this.hasAttendedInfoSession = this.draftDetails.hasAttendedPublicInformatioinSession;
        this.wantsToSpeakToSubmission = this.draftDetails.wantsToSpeakToSubmission;
        this.submissionText = this.draftDetails.submissionText;
        this.submissionFiles = this.draftDetails.attachments ? this.draftDetails.attachments.map(f => {
            return {
                attachmentId: f.attachmentId,
                submissionId: f.submissionId,
                fileName: f.fileName,
                name: f.fileName,
                size: f.fileSize
            }
        }) : [];
        this.completedWrittenSubmission = this.submissionText.length !== 0;
        // Populate submission map from draftDetails.SubmissionMap
        if (this.draftDetails.submissionMap.length > 0) {
            this.submissionMap = this.draftDetails.submissionMap.map(segment => { return { ...segment, geometry: JSON.parse(segment.geometry) } });
            
            modelingMapStore.setShowLandingDialog(false);

            if(loadMapDetails) {
                modelingMapStore.loadDraftData(this.draftDetails.submissionMap); 
            }
        }
        else {
            modelingMapStore.setShowLandingDialog(true);

            if(loadMapDetails) {
                modelingMapStore.loadData();
            }
        }
    }

    @action
    setIsWrittenSubmissionFormDirty(dirty) {
        this.isWrittenSubmissionFormDirty = dirty;
        this.sessionStore.rootStore.setDirty(dirty);
    }

    @action
    setConfirmMappingSubmissionDialogOpen(open) {
        this.confirmMappingSubmissionDialogOpen = open;
    }

    @action
    setAttachmentsHelperText(text) {
        this.attachmentsHelperText = text;
    }

    @action
    setSubmissionCompleteDialogOpen(open, submittedDraftId) {
        this.submissionCompleteDialogOpen = open;
        this.submittedDraftId = open ? submittedDraftId : null;
    }

    @action
    deleteDraft(submissionId) {
        const { rootStore, reviewStore } = this.sessionStore;
        rootStore.setBusy(true);

        submissionService.deleteDraft(submissionId).then(() => {
            rootStore.setBusy(false);
            this.getSubmissions();
            reviewStore.currentDraftSubmissionId = null;
            window.localStorage.removeItem(submissionId);
        }).catch((error) => {
            rootStore.setBusy(false);
            this.deleteDraftError = error;
        });
    }

    @action
    submitDraft(submissionId) {
        const { rootStore } = this.sessionStore;
        rootStore.setBusy(true);

        submissionService.submitDraft(submissionId).then(response => {
            rootStore.setBusy(false);
            runInAction(() => {
                this.createSubmissionResult = response;
            });

            this.setSubmissionCompleteDialogOpen(true, submissionId);
        }).catch((error) => {
            rootStore.setBusy(false);
            this.createSubmissionError = error;

            this.setSubmissionCompleteDialogOpen(true, submissionId);
        });
    }

    get totalAttachmentsSize() {
        let result = 0;

        this.submissionFiles.forEach(file => {
            result += file.size;
        });

        return result;
    }

    getCurrentStage(review) {
        let result = Enums.SubmissionStage.None;

        if (review && review.isPreliminarySubmissionsOpen && !review.isPreliminarySubmissionsClose) {
            result = Enums.SubmissionStage.Preliminary;
        }

        if (review && review.isPreliminaryReportPublished && !review.isResponseSubmissionsClose) {
            result = Enums.SubmissionStage.Response;
        }

        if (review && review.isAddendumSubmissionsOpen && !review.isAddendumSubmissionsClose) {
            result = Enums.SubmissionStage.Addendum;
        }

        return result;
    }

    @computed get activeSubmissions() {
        const { reviewStore } = this.sessionStore;
        return this.currentSubmissions.filter(
                s => s.stage === reviewStore.submissionStage &&
                s.review.isReviewEnabled &&
                s.review.reviewType === reviewStore.reviewDetails.reviewType &&
                s.review.councilId === reviewStore.reviewCouncilId);
    }

    getDraftSubmission(submissions, stage, reviewType) {
        const { reviewStore } = this.sessionStore;
        let result = [];

        if (submissions) {
            result = submissions.filter(
                s => s.isDraft &&
                    s.stage === stage &&                    
                    s.review.isReviewEnabled &&
                    s.review.reviewType === reviewType && 
                    s.review.councilId === reviewStore.reviewCouncilId);
        }

        return result[0];
    }

    getCompletedSubmissions(submissions, stage, reviewType) {
        const { reviewStore } = this.sessionStore;
        let result = [];
 
        if (submissions) {
            result = submissions.filter(
                s => !s.isDraft &&
                    s.stage === stage &&                    
                    s.review.isReviewEnabled &&
                    s.review.reviewType === reviewType &&
                    s.review.councilId === reviewStore.reviewCouncilId);
        }

        return result;
    }

    hasDraftSubmission(submissions, draftStage, reviewType) {
        const draft = this.getDraftSubmission(submissions, draftStage, reviewType);
        
        return !!draft;
    }

    hasCompletedSubmissions(submissions, stage, reviewType) {
        const completed = this.getCompletedSubmissions(submissions, stage, reviewType);

        return completed.length > 0;
    }

    getFormattedSubmissionDate(date) {
        let result = '';

        if (date) {
            result = moment(date).format('h.mm a DD MMMM YYYY');
        }

        return result;
    }
}
