import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import MapGuidelineDetails from './map-guideline-details';
import { Enums } from '../../../enums';
import Step1DetailsImage from './../../../../static/images/map-guide-stepA.svg';
import Step2DetailsImage from './../../../../static/images/map-guide-stepB.svg';
import Step3DetailsImage from './../../../../static/images/map-guide-stepD.svg';
import Step4DetailsImage from './../../../../static/images/map-guide-stepE.svg';
import Step5DetailsImage from './../../../../static/images/map-guide-stepH.svg';
import Step6DetailsImage from './../../../../static/images/map-guide-stepJ.svg';
import Step7DetailsImage from './../../../../static/images/map-guide-stepK.svg';
import Step8DetailsImage from './../../../../static/images/map-guide-stepG.svg';
import Step9DetailsImage from './../../../../static/images/map-guide-stepC.svg';
import Step10DetailsImage from './../../../../static/images/map-guide-stepF.svg';
import Step11DetailsImage from './../../../../static/images/map-guide-stepL.svg';


@inject('reviewStore', 'modelingMapStore', 'submissionStore')
@observer
class MapGuidelineContent extends React.Component {
    render() {
        const { activeStep, handleChangeActiveStep, reviewStore, submissionStore } = this.props;
        const groundUpModellingSteps = [
            {
                title : "Create wards",
                image : Step1DetailsImage,
                content : `Click on the <b>Add ward button</b> to create new wards. Adjust the number of councillors by clicking on the <b>+ and - buttons</b> in the councillors column.`
            },
            {
                title : "Select blocks",
                image : Step2DetailsImage,
                content : `Use the <b>point, polygon</b> or <b>rectangle tool</b> to select blocks on the map. The panel shows the number of selected voters in your selected blocks.`
            },
            {
                title : "Assign to ward",
                image : Step3DetailsImage,
                content : `Once you have selected blocks on the map, you'll be able to <b>assign them to a ward</b>. The panel will show you the effect of re-assigning blocks from one ward to another. Hit Confirm to lock in your choice.`
            },
            {
                title : "Check the effect of your change",
                image : Step4DetailsImage,
                content : `Continue assigning blocks to wards until they fall within the +/-10% deviation.`
            },
            {
                title : "View existing wards",
                image : Step5DetailsImage,
                content : `You can view existing wards at any time by going to the layers panel and clicking on <b>Existing wards</b>. Existing boundaries will appear with black outlines, and any boundary changes you've made will appear with white outlines.`
            },
            {
                title : "Find unassigned blocks ",
                image : Step6DetailsImage,
                content : `If you remove blocks from a ward they will become <b>Unassigned</b>. You can zoom to these blocks by clicking on <b>Unassigned</b> in the right hand panel. Click again to remove the pins.`
            },
            {
                title : "Check your map",
                image : Step7DetailsImage,
                content : `Click on <b>Check my map</b> or <b>Save</b> to validate your map. The validation highlights issues that may be present in your model. You can submit your map without resolving these issues. This information is provided to ensure you haven't accidentally overlooked an aspect of your submission.`
            },
            {
                title : "Before you submit",
                content : `<br/>Things to note when finalising your submission:
                            <br/>
                            <ul>
                            <li>Your submission can cover the whole Council, or just the area(s) you're interested in.</li>
                            <li>You'll have an opportunity to include a written statement after you've completed your model. This is where you can explain the choices you've made, suggest ward names, and share anything else that's relevant to your submission.</li>
                            <li>A limitation of the mapping tool is that you can't split blocks. If your preferred boundary does split one or more blocks, you can explain your preferred boundary in your written statement.</li>
                            <li>It is okay to submit your map with validation errors. This information is provided to ensure you haven't accidentally overlooked an aspect of your submission.</li>
                            <li>This tool does not include elector projections. The panel considers elector growth and decline when assessing medium and long-term viability of models.</li>
                            </ul>`
            }
        ]
        const existingModellingSteps = groundUpModellingSteps.slice(1,8);
        existingModellingSteps.splice(3,0, {
            title : 'Change the number of councillors',
            image : Step8DetailsImage,
            content : `You can adjust the number of total councillors or councillors assigned to each ward by clicking on the <b>+/- buttons</b> in the right hand panel. Ensure you assign all councillors to wards.`
        })
        const wardBoundaryModellingsSteps = groundUpModellingSteps.slice(1,7);
        wardBoundaryModellingsSteps.splice(0,0, {
            title : 'Select a ward',
            image : Step9DetailsImage,
            content : `Click on a ward name in the right hand panel or use the zoom tool in the lower right hand corner of the map to zoom to <b>ward</b>.`
        })
        wardBoundaryModellingsSteps.splice(3,1, {
            title : "Check the effect of your change",
            image : Step10DetailsImage,
            content : `Continue assigning blocks to wards until they fall within the +/-10% deviation.`
        })
        wardBoundaryModellingsSteps.splice(6,1, {
            title : "Check your map",
            image : Step11DetailsImage,
            content : `Click on <b>Check my map</b> or <b>Save</b> to validate your map. The validation highlights issues that may be present in your model. You can submit your map without resolving these issues. This information is provided to ensure you haven't accidentally overlooked an aspect of your submission.`
        })
        wardBoundaryModellingsSteps.splice(7,0, {
            title : "Before you submit",
            content : `<br/>Things to note when finalising your submission:
                        <br/>
                        <ul>
                        <li>Your submission can cover the whole Council, or just the area(s) you're interested in.</li>
                        <li>You'll have an opportunity to include a written statement after you've completed your model. This is where you can explain the choices you've made and share anything else that's relevant to your submission.</li>
                        <li>A limitation of the mapping tool is that you can't split blocks. If your preferred boundary does split one or more blocks, you can explain your preferred boundary in your written statement.</li>
                        <li>It is okay to submit your map with validation errors. This information is provided to ensure you haven't accidentally overlooked an aspect of your submission.</li>
                        <li>This tool does not include elector projections. The panel considers elector growth and decline when assessing medium and long-term viability of models.</li>
                        </ul>`
        })
        const userSelectedModellingOption = JSON.parse(window.localStorage.getItem(submissionStore.draftDetails.submissionId));
        const targetModellingSteps = (reviewStore.reviewDetails.reviewType === Enums.ReviewType.Full || reviewStore.reviewDetails.reviewType === Enums.ReviewType.ResponseStage) && (reviewStore.isGroundUpModeSelected || (userSelectedModellingOption && userSelectedModellingOption.groundUp)) ? groundUpModellingSteps :
                                        (reviewStore.reviewDetails.reviewType === Enums.ReviewType.Full || reviewStore.reviewDetails.reviewType === Enums.ReviewType.ResponseStage) && (reviewStore.isExistingModeSelected || (userSelectedModellingOption && userSelectedModellingOption.existing)) ? existingModellingSteps : wardBoundaryModellingsSteps;
        return (
            <Grid container >
                <SwipeableViews
                    index={activeStep}
                    onChangeIndex={handleChangeActiveStep}
                >
                    {targetModellingSteps.map((steps, index) => {
                      return (  index != 7 ?
                        <Grid item xs={12} >
                            <MapGuidelineDetails
                                title={index + 1 + ". " + steps.title}
                                image={steps.image}
                                content={steps.content} />
                        </Grid> :
                        <Grid item xs={12} >
                        <MapGuidelineDetails
                            title={index + 1 + ". " + steps.title}
                            content={steps.content} />
                        </Grid>)
                    })}
                </SwipeableViews>
            </Grid>
        );
    }
}

MapGuidelineContent.propTypes = {
    activeStep: PropTypes.number,
    handleChangeActiveStep: PropTypes.func,
    reviewStore : PropTypes.object,
    submissionStore : PropTypes.object
};

export default MapGuidelineContent;