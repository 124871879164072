import React from 'react'
import PropTypes from 'prop-types';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, TableRow, TableCell, TableBody, Grid, TextField, ClickAwayListener, Tooltip, IconButton } from '@material-ui/core';
import { GithubPicker } from 'react-color';
import IconDelete from '-!svg-react-loader?name=IconDelete!../../../static/images/delete-icon.svg';
import IconPencil from '-!svg-react-loader?name=IconPencil!../../../static/images/icon-pencil-2.svg';
import IconPlus from '-!svg-react-loader?name=IconPlus!../../../static/images/icon-plus.svg';
import IconMinus from '-!svg-react-loader?name=IconMinus!../../../static/images/icon-minus.svg';
import { inject, observer } from 'mobx-react';
import FloatingBox from '../floating-box';
import { observable, action } from 'mobx'
import { Enums } from '../../enums';

const styles = theme => ({
    segmentTableCell: {
        padding: '0px',
        verticalAlign: 'middle'
    },
    segmentName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '116px'
    },
    numberTableCell: {
        padding: '0px'
    },
    segmentRow: {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        height: '45px'
    },
    selectRow: {
        cursor: 'pointer',
        '&:hover': {
            background: '#eaeaea'
        }
    },
    selectedRow: {
        background: '#e0e0e0',
        '&:hover': {
            background: '#d0d0d0'
        }
    },
    evenRow: {
        background: theme.palette.white
    },
    nameTableCol: {
        display: 'flex',
        alignItems: 'center'
    },
    segmentColourSwatch: {
        height: '18px',
        width: '18px',
        border: 'none',
        padding: '0px',
        borderRadius: '2px',
        cursor: 'pointer'
    },
    segmentNameEdit: {
        maxWidth: '110px'
    },
    clickableIcon: {
        cursor: 'pointer',
        paddingLeft: '4px',
        '@media print': {
            display: 'none !important'
        }
    },
    editIcon: {
        marginBottom: '4px',
        width: '14px',
        height: '14px',
        paddingLeft: '6px',
        '@media print': {
            display: 'none !important'
        }
    },
    deleteIcon: {
        width: '16px',
        height: '16px',
        marginRight: '10px',
        paddingBottom: '4px',
        paddingLeft: '6px',
    },
    withinBoundsDeviation: {
        color: '#438600'
    },
    outOfBoundsDeviation: {
        color: '#ca0000'
    },
    tooltipBottom: {
        padding:'0px',
    },
    noWrap: {
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap !important'
    },
    tableHeader: {
        padding: '0px',
        borderBottom: 'none'
    },
    districtsHeader: {
        width: '42%'
    },
    councillorHeader: {
        width: '20%'
    },
    votersHeader: {
        width: '15%'
    },
    deviationHeader: {
        width: '18%'
    },
    deviationHeaderWard: {
        width: '20%'
    },
    deleteHeader: {
        width: '5%'
    },
    districtsHeaderText: {
        marginLeft: '25px'
    },
    segmentNameContainer: {
        marginLeft: '10px'
    },
    segmentShowEditContainer: {
        marginLeft: '25px'
    },
    districtNameCell: {
        minWidth: '180px'
    },
    numMembersBox: {
        borderColor: theme.palette.secondary.main,
        borderStyle: 'solid',
        borderWidth: '1px',
        background: 'white',
        borderRadius: '2px',
        width: '36px',
        height: '33px',
        padding: '5px',        
    },
    primaryIcon: {
        '& > path': {
            fill: theme.palette.secondary.main + ' !important',
        },
        '@media print': {
            display: 'none !important'
        }
    },
    disabledIcon: {
        '& > path': {
            fill: 'rgba(0, 0, 0, 0.25) !important',
        },
        '@media print': {
            display: 'none !important'
        }
    },
    alignCenter: {
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    alignRight: {
        justifyContent: 'right',
        textAlign: 'right',
        alignItems: 'right',
        display: 'flex'
    },
    toolTip : {
            color: theme.palette.white,
            backgroundColor: theme.palette.secondary.main,
            padding: '10px'
    },
    disableRipple: {
        padding:'0px',
        '&:hover': {
          backgroundColor: 'transparent'
        },
    }
});

@inject('msgBoxStore', 'modelingMapStore', 'reviewStore')
@observer
class SegmentList extends React.Component {

    constructor(props) {
        super();
        this.modelingStore = props.modelingMapStore;
        this.reviewStore = props.reviewStore;
    }    

    @observable editSegmentName = false;
    @observable colourPickerActive = false;
    @observable colourPickerAnchor = null;
    @observable showDeviationInfo = false;
    @observable selectedSegmentName = '';
    @observable hasEditedSegmentName = false;
    @observable isScrollToListReq = false;

    static propTypes = {
        classes: PropTypes.object.isRequired,
        modelingStore: PropTypes.object,
        reviewStore: PropTypes.object,
        finishBtnFn: PropTypes.func,
    }

    componentDidUpdate(){
        if(this.isScrollToListReq){
            this.scrollToEditedSegment();
            this.isScrollToListReq = false;
        }
    }

    render() {
        const { classes, segments, keyPrefix, idProperty, allowSelection } = this.props;
        const { selectedSegmentId, colourSwatches, unassignedSegmentMembers } = this.modelingStore;
        const allowEditing = (this.reviewStore.reviewDetails.reviewType == Enums.ReviewType.Full || this.reviewStore.reviewDetails.reviewType == Enums.ReviewType.ResponseStage);
        return <>
            { segments && segments.length > 0 &&
                (<Table>
                    <colgroup>
                        <col className={[classes.tableHeader, classes.districtsHeader].join(' ')} />
                        <col className={[classes.tableHeader, classes.councillorHeader].join(' ')} />
                        <col className={[classes.tableHeader, classes.votersHeader].join(' ')} />
                        <col className={[classes.tableHeader, (this.reviewStore.reviewDetails.reviewType == Enums.ReviewType.WardBoundary) ? classes.deviationHeaderWard : classes.deviationHeader].join(' ')} />
                        {(this.reviewStore.reviewDetails.reviewType != Enums.ReviewType.WardBoundary) && <col className={[classes.tableHeader, classes.deleteHeader].join(' ')} />}
                    </colgroup>
                    <TableBody>
                        {segments && segments.length > 0 && segments.map((segment) => {
                            return (
                                <TableRow className={[classes.segmentRow, classes.noWrap, allowSelection ? classes.selectRow: null, segment.properties[idProperty] == selectedSegmentId ? classes.selectedRow : classes.evenRow].join(' ')} 
                                 key={`${keyPrefix + segment.properties[idProperty]}`}
                                 id={`${keyPrefix + segment.properties[idProperty]}`}
                                 onClick={(event) => { if(allowSelection) this.handleSelectedSegment(event, segment.properties[idProperty]) }}>
                                    <TableCell className={[classes.segmentTableCell, classes.districtNameCell].join(' ')} >
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignItems={'center'} spacing={0} className={[classes.noWrap, classes.segmentShowEditContainer].join(' ')}>
                                            {this.colourPickerAnchor && this.colourPickerActive && selectedSegmentId === segment.properties[idProperty] &&
                                                <ClickAwayListener onClickAway={event=>this.toggleColourPicker(event, segment.properties[idProperty])}>
                                                    <FloatingBox zIndex={1} anchorEl={this.colourPickerAnchor} position='under-left' offset={{ x: -5, y: 10 }} onClick={this.stopPropogation}>
                                                        <GithubPicker colors={colourSwatches} width='125px' onChange={(event) => { this.setSegmentColour(event.hex) }} />
                                                    </FloatingBox>
                                                </ClickAwayListener>
                                            }
                                            <Grid item id={keyPrefix+'_ColorSwatch_'+segment.properties[idProperty]} className={classes.segmentColourSwatch} style={{ background: segment.properties.colour }} onClick={event=>this.toggleColourPicker(event, segment.properties[idProperty])}>
                                                {/*Colour swatch*/}
                                            </Grid>
                                            <Grid item xs={10} className={classes.segmentNameContainer}>
                                                {this.editSegmentName && segment.properties[idProperty] == selectedSegmentId ?
                                                    <ClickAwayListener onClickAway={(event) => this.handleEditSegmentName(event, segment.properties[idProperty])}>
                                                        <TextField value={this.selectedSegmentName} autoFocus={true} inputProps={{ className: classes.segmentNameEdit, maxLength: 26 }} 
                                                        onClick={this.stopPropogation} onChange={(event) => { this.updateSegmentName(event.target.value) }} onKeyDown={(event) => this.handleEditSegmentKeyDown(event) }/>
                                                    </ClickAwayListener>
                                                    : <Grid className={classes.nameTableCol}>
                                                        <span className={classes.segmentName}>{segment.properties.name}</span>
                                                        {allowEditing &&
                                                        <Tooltip
                                                            PopperProps={{ style: { marginTop: -16 } }}
                                                            title={<React.Fragment>
                                                                <Typography className={classes.toolTip}>
                                                                    Click here to edit ward name
                                                                </Typography>
                                                            </React.Fragment>
                                                            } 
                                                            classes={{tooltipPlacementBottom: classes.tooltipBottom }}
                                                            placement='bottom'>
                                                                <IconButton className={classes.disableRipple} aria-label="View">
                                                                    <IconPencil className={[classes.clickableIcon, classes.editIcon].join(' ')} onClick={(event) => this.handleEditSegmentName(event, segment.properties[idProperty])} />
                                                                </IconButton>
                                                        </Tooltip>}
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell className={[classes.segmentTableCell].join(' ')}>
                                    {(this.reviewStore.reviewDetails.reviewType != Enums.ReviewType.WardBoundary) && <Typography color={'textPrimary'}>
                                        <Grid style={{padding:'0px',paddingLeft:'10px'}} container direction={'row'} alignItems={'center'} spacing={2} justifyContent="center">
                                                <Grid style={{paddingRight:'4px'}} item>
                                                    <IconMinus className={[classes.clickableIcon, segment.properties.members > 1? classes.primaryIcon: classes.disabledIcon].join(' ')}  onClick={(event) => this.removeSegmentMember(event, segment.properties.id)} />
                                                </Grid>
                                                <Grid style={{padding:'0px'}} item className={classes.numMembersBox} container alignItems={'center'} justifyContent="center">
                                                    <Typography color={'textPrimary'} className={classes.alignCenter} style={{marginTop:'4px'}}>
                                                        {segment.properties.members}
                                                    </Typography>
                                                </Grid>
                                                <Grid style={{padding:'0px'}} item>
                                                    <IconPlus className={[classes.clickableIcon, unassignedSegmentMembers? classes.primaryIcon: classes.disabledIcon].join(' ')} onClick={(event) => {this.addSegmentMember(event, segment.properties.id);}}/>
                                                </Grid>
                                        </Grid>
                                    </Typography>}
                                    {(this.reviewStore.reviewDetails.reviewType == Enums.ReviewType.WardBoundary) && <Typography color={'textPrimary'} className={classes.alignCenter}>
                                            {segment.properties.members.toLocaleString()}
                                        </Typography>}
                                    </TableCell>
                                    <TableCell className={[classes.segmentTableCell, classes.numberTableCell].join(' ')}>
                                        <Typography color={'textPrimary'} className={classes.alignRight} style={{ paddingRight: (this.reviewStore.reviewDetails.reviewType == Enums.ReviewType.WardBoundary) ? "14px" : '0px' }}>
                                            {segment.properties.electors.toLocaleString()}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={[classes.segmentTableCell, classes.numberTableCell].join(' ')} >
                                        <Typography className={[this.deviationClass(segment.properties.deviation), classes.alignRight].join(' ')} style={{ paddingRight: (this.reviewStore.reviewDetails.reviewType == Enums.ReviewType.WardBoundary) ? "14px" : '0px' }}>
                                            {segment.properties.deviation > 0 && '+'}{segment.properties.deviation.toFixed(2)}%
                                        </Typography>
                                    </TableCell>
                                    {(this.reviewStore.reviewDetails.reviewType != Enums.ReviewType.WardBoundary) && <TableCell className={classes.segmentTableCell}>
                                        <Grid className={classes.nameTableCol}>
                                            { allowEditing && 
                                                <Tooltip
                                                PopperProps={{ style: { marginTop: -16 } }}
                                                title={<React.Fragment>
                                                    <Typography className={classes.toolTip}>
                                                        Click here to delete ward
                                                    </Typography>
                                                </React.Fragment>
                                                } 
                                                classes={{tooltipPlacementBottom: classes.tooltipBottom }}
                                                placement='bottom'>
                                                    <IconButton className={classes.disableRipple} aria-label="View">
                                                        <IconDelete className={[classes.clickableIcon, classes.deleteIcon].join(' ')} onClick={(event) => this.confirmDelete(event, segment.properties[idProperty])} />
                                                    </IconButton>
                                            </Tooltip>}
                                        </Grid>
                                    </TableCell>} 
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>)
            }
        </>
    }

    @action stopPropogation = (event)=>{
        event.stopPropagation();
    }

    @action handleEditSegmentName = (event, id)=>{
        event.stopPropagation();
        this.selectSegment(id);
        this.toggleEditSegmentName()
    }

    toggleColourPicker = (event, id) => {
        event.stopPropagation();        
        this.selectSegment(id);
        this.colourPickerAnchor = event.target;
        this.colourPickerActive = !this.colourPickerActive;
    }

    setSegmentColour = (newColour) => {
        this.modelingStore.selectedSegment.properties.colour = newColour;
        this.colourPickerActive = false;
        this.modelingStore.setIsMapDirty(true);
    }

    handleEditSegmentKeyDown = (event) => { 
        if (event.key === "Enter") {
            this.toggleEditSegmentName();
        }
    }

    @action toggleEditSegmentName = () => {
        this.editSegmentName = !this.editSegmentName;
        if(this.hasEditedSegmentName){
            this.isScrollToListReq = true;
        }
        if(!this.editSegmentName && this.selectedSegmentName) {
            this.modelingStore.selectedSegment.properties.name = this.selectedSegmentName;
            this.hasEditedSegmentName = false;
        }
    }

    scrollToEditedSegment = () => {
        const { selectedSegment } = this.modelingStore;
        const { keyPrefix, idProperty } = this.props;
        const id = keyPrefix + selectedSegment.properties[idProperty];
        const elemToScrollTo = document.getElementById(id);
        if (elemToScrollTo) {
            elemToScrollTo.scrollIntoView();
        }
    }

    @action updateSegmentName = (newName) => {
        this.selectedSegmentName = newName;
        this.hasEditedSegmentName = true;
        this.modelingStore.setIsMapDirty(true);
    }

    selectSegment = (id) => {
        const prevSelectedSegment = this.modelingStore.selectedSegment;
        const { idProperty } = this.props;

        if(id !== prevSelectedSegment.properties[idProperty]) {

            if(this.hasEditedSegmentName) {
                this.toggleEditSegmentName();
            }
            else {
                this.editSegmentName = false;
            }
        }

        this.modelingStore.selectSegment(id);
        this.selectedSegmentName = this.hasEditedSegmentName &&  id === prevSelectedSegment.properties[idProperty] ? this.selectedSegmentName : this.modelingStore.selectedSegment.properties.name;
    }

    
    handleSelectedSegment = (event, id) => {
        console.log('inside handleSelectedSegment',event.bubbles);
        this.selectSegment(id);
        this.displaySelectedSegment();
    }

    displaySelectedSegment = () => {
        this.modelingStore.displaySelectedSegment();
    }
    
    deviationClass = (deviation) => {
        return (deviation > -10 && deviation < 10) ? this.props.classes.withinBoundsDeviation : this.props.classes.outOfBoundsDeviation;

    }

    confirmDelete = (event, id) => {
        event.stopPropagation();
        this.selectSegment(id);
        const { selectedSegment } = this.modelingStore;

        this.props.msgBoxStore.show({
            content: `Do you wish to delete ${selectedSegment.properties.name}?`,
            header: 'Delete Ward',
            size: 'xs',
            inputOptions: { type: 'NONE' },
            hideCancel: false,
            confirmButton: 'Yes',
            cancelButton: 'No'
        }).then(() => {
            this.modelingStore.deleteSegment(this.modelingStore.selectedSegmentId);
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }

    addSegmentMember = (event, id) => {
        event.stopPropagation();
        this.modelingStore.addMember(id);
    }

    removeSegmentMember = (event, id) => {
        event.stopPropagation();
        this.modelingStore.removeMember(id);
    }
}

SegmentList.propTypes = {
    msgBoxStore: PropTypes.object,
    modelingMapStore: PropTypes.object,
    reviewStore: PropTypes.object,
    segments: PropTypes.any,
    keyPrefix: PropTypes.string.isRequired,
    idProperty: PropTypes.string.isRequired,
    allowEditing: PropTypes.bool.isRequired,
    allowSelection: PropTypes.bool.isRequired
}

export default compose(
    withStyles(styles)
)(SegmentList)
