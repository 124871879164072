import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { action, observable, computed } from 'mobx';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import LinkIcon from '../../../static/images/shape-primary.svg';
import { Tools } from '../../tools';
import StyledPrimaryContainedButton from '../styled-primary-contained-button'

const colorWhite = '#fafafa';
const colorError = '#e13023';

const styles = theme => ({
    instructionBox: {
        backgroundColor: colorWhite
    },
    instructionBoxText: {
        margin: '10px'
    },
    submitButton: {
        textTransform: 'none',
        width: '150px'
    },
    textField: {
        width: '100%'
    },
    selectField: {
        width: '100%',
        '& fieldset': {
            paddingLeft: '8px !important'
        },
        '& fieldset legend': {
            width: '0px !important'
        }
    },
    firstNameField: {
        '& fieldset': {
            paddingLeft: '8px !important'
        },
        '& fieldset legend': {
            width: '75px !important'
        }
    },
    contactFirstNameField: {
        '& fieldset': {
            paddingLeft: '8px !important'
        },
        '& fieldset legend': {
            width: '115px !important'
        }
    },
    lastNameField: {
        '& fieldset': {
            paddingLeft: '8px !important'
        },
        '& fieldset legend': {
            width: '75px !important'
        }
    },
    contactLastNameField: {
        '& fieldset': {
            paddingLeft: '8px !important'
        },
        '& fieldset legend': {
            width: '115px !important'
        }
    },
    linkIcon: {
        fill: 'black'
    },
    privacyPolicyLink: {
        width: '16px',
        height: '16px',
        marginTop: '16px'
    },
    marginTopGrid: {
        marginTop: '20px'
    },
    lbHrFieldsCompulsoryContainer: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    fieldCompulsoryText: {
        fontStyle: 'italic !important'
    },
    formLabel: {
        fontWeight: '600 !important'
    },
    hr: {
        height: '1px',
        background: '#C4C4C4',
        border: 'none'
    },
    lastPositionForm: {
        order: 1
    },
    hiddenLabel: {
        display: 'none'
    },
    subtitle2: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    },
    errorMsg: {
        color: colorError
    },
    stateHelperText: {
        display: 'none'
    },
    rootContainer: {
        '&& .MuiOutlinedInput-root': {
            borderRadius: 0,
            '& fieldset legend span, .MuiSelect-root span': {
                visibility: 'hidden',
                width: 0
            },
        },
        '&& .Mui-error input::placeholder': {
            color: colorError,
            opacity: 1
        }
    }
})

export function LastNameGridContainer(props) {
    const isMobile = Tools.isMobile();
    return <Grid item xs={12} md={6} className={props.isOrganisation && !isMobile ? props.className : null}>{props.children}</Grid>;
}

LastNameGridContainer.propTypes = {
    children: PropTypes.array,
    isOrganisation: PropTypes.bool,
    className: PropTypes.string
}

@inject('profileStore')
@observer
class ContactDetailsContent extends React.Component {

    @observable inputFocusName = '';
    @observable hasAutoFilledEnabled = false;

    constructor(props) {
        super(props);
        this.scrollElementRef = React.createRef();
    }

    // componentDidMount() {
    //     this.props.profileStore.checkFormValidity();
    // }

    handleIsOrganisationChange = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setIsOrganisation(event.target.value === '0' ? false : true);
    };

    handleOrgNameChange = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setOrganisationName(event.target.value);
        profileStore.validateOrganisationName();
    };

    handleFirstNameChange = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setFirstName(event.target.value);
        profileStore.validateFirstName();
    };

    handleLastNameChange = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setLastName(event.target.value);
        profileStore.validateLastName();
    };

    handleIsAuthorisedBehalfOfOrgChange = () => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setIsAuthorisedBehalfOfOrg(!profileStore.isAuthorisedBehalfOfOrg);
        profileStore.validateLastIsAuthorisedBehalfOfOrg();
    };

    handleAddressLine1Change = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setAddressLine1(event.target.value);
        profileStore.validateAddressLine1();
    };

    handleAddressLine2Change = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setAddressLine2(event.target.value);
    };

    handleSuburbChange = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setSuburb(event.target.value);
        profileStore.validateSuburb();
    };

    handlePostcodeChange = event => {
        const { profileStore } = this.props;
        const onlyDigits = event.target.value.replace(/[^0-9]/g, '');

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setPostcode(onlyDigits);
        profileStore.validatePostcode();
    };

    handleStateChange = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setState(event.target.value);
        profileStore.validateState();
    };

    handleEmailAddressChange = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setEmailAddress(event.target.value);
        profileStore.validateEmailAddress();
    };

    handleReEnterEmailAddressChange = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setReEnterEmailAddress(event.target.value);
        profileStore.validateReEnterEmailAddress();
    };

    handlePhoneNumberChange = event => {
        const { profileStore } = this.props;
        const onlyDigits = event.target.value.replace(/[^0-9]/g, '');

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setPhoneNumber(onlyDigits);
        profileStore.validatePhoneNumber();
    };

    handlePreferredContactMethodChange = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setPreferredContactMethod(event.target.value);
    };

    handlePasswordChange = event => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setPassword(event.target.value);
        profileStore.validatePassword();
    };

    handleAgreeToVecPrivacyPolicyChange = () => {
        const { profileStore } = this.props;

        profileStore.setIsContactDetailsFormDirty(true);
        profileStore.setAgreeToVecPrivacyPolicy(!profileStore.agreeToVecPrivacyPolicy);
        profileStore.validateAgreeToVecPrivacyPolicy();
    };

    handleSubmitClick = () => {
        const { profileStore } = this.props;

        profileStore.checkFormValidity();
        if (profileStore.isContactDetailsFormValid) {
            profileStore.saveProfile();
        } else {
            this.scrollElementRef.current.scrollIntoView(true);
        }
    }

    handleAutoFill = (event) => {
        if (event.animationName === "mui-auto-fill") {
            this.hasAutoFilledEnabled = true;
        }
    };

    @action handleOnFocusInput = (event) => {
        this.inputFocusName = event.target.name;
    }

    @action handleOnBlurInput = () => {
        this.inputFocusName = "";
        this.hasAutoFilledEnabled = false;
    }


    @computed get validationErrors() {
        const { profileStore } = this.props;
        let validationMessage = [];
        profileStore.organisationNameError && validationMessage.push(profileStore.organisationNameHelperText);
        profileStore.firstNameError && validationMessage.push(profileStore.firstNameHelperText);
        profileStore.lastNameError && validationMessage.push(profileStore.lastNameHelperText);
        profileStore.emailAddressError && validationMessage.push(profileStore.emailAddressHelperText);
        profileStore.isAuthorisedBehalfOfOrgError && validationMessage.push(profileStore.isAuthorisedBehalfOfOrgHelperText);
        profileStore.addressLine1Error && validationMessage.push(profileStore.addressLine1HelperText);
        profileStore.suburbError && validationMessage.push(profileStore.suburbHelperText);
        profileStore.postcodeError && validationMessage.push(profileStore.postcodeHelperText);
        profileStore.stateError && validationMessage.push(profileStore.stateHelperText);
        profileStore.phoneNumberError && validationMessage.push(profileStore.phoneNumberHelperText);
        profileStore.agreeToVecPrivacyPolicyError && validationMessage.push(profileStore.agreeToVecPrivacyPolicyHelperText);
        return validationMessage;
    }



    render() {
        const { classes, profileStore } = this.props;

        return (
            <Grid container className={classes.rootContainer}>
                <Grid item xs={12} ref={this.scrollElementRef}>
                    <Typography id="fields-compulsory-text" variant="h2" className={[classes.subtitle2, classes.fieldCompulsoryText].join(' ')}>All fields are compulsory.</Typography>
                </Grid>
                {this.validationErrors.length > 0 &&
                    <Grid item xs={12} className={classes.marginTopGrid}>
                        <FormHelperText>
                            Please fix the following errors and try again
                        </FormHelperText>
                        <ul>
                            {this.validationErrors.map((x) => (
                                <li key={x} className={classes.errorMsg}><FormHelperText dangerouslySetInnerHTML={{ __html: x }}></FormHelperText></li>
                            ))}
                        </ul>
                    </Grid>}
                <Grid item xs={12} className={classes.lbHrFieldsCompulsoryContainer}>
                    <hr className={classes.hr} />
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset" margin="normal">
                        <FormLabel component="legend">
                            <Typography variant="h3" color="secondary" className={[classes.subtitle2, classes.formLabel].join(' ')}>Are you an individual or an organisation?</Typography>
                        </FormLabel>
                        <RadioGroup
                            aria-label="Are you an individual or an organisation?"
                            name="isOrg"
                            value={profileStore.isOrganisation ? "1" : "0"}
                            onChange={this.handleIsOrganisationChange}
                            row={true}
                        >
                            <FormControlLabel value="0" control={<Radio color="primary" inputProps={{ 'aria-label': 'Individual' }} />} label={<Typography variant="h4" className={classes.subtitle2}>Individual</Typography>} />
                            <FormControlLabel value="1" control={<Radio color="primary" inputProps={{ 'aria-label': 'Organisation' }} />} label={<Typography variant="h4" className={classes.subtitle2}>Organisation</Typography>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid container spacing={3}>
                    <Grid container spacing={3} style={{ margin: "0px" }}>
                        {
                            profileStore.isOrganisation &&
                            <Grid item xs={12} md={6}>
                                <label htmlFor="organisationName">
                                    <Typography variant="h5" color="secondary" className={[classes.subtitle2, classes.formLabel].join(' ')}>Organisation name</Typography>
                                </label>
                                <TextField
                                    id="organisationName"
                                    name="OrganisationName"
                                    className={classes.textField}
                                    placeholder={profileStore.organisationName || this.inputFocusName === "OrganisationName" || this.hasAutoFilledEnabled ? "" : "Organisation name"}
                                    value={profileStore.organisationName}
                                    onChange={this.handleOrgNameChange}
                                    onFocus={this.handleOnFocusInput}
                                    onBlur={this.handleOnBlurInput}
                                    onAnimationStart={this.handleAutoFill}
                                    InputLabelProps={{
                                        shrink: false
                                    }}
                                    inputProps={{
                                        maxLength: 150
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    error={profileStore.organisationNameError}
                                    helperText={profileStore.organisationNameHelperText}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            <label htmlFor="firstname">
                                <Typography variant="h5" color="secondary" className={[classes.subtitle2, classes.formLabel].join(' ')}>
                                    {profileStore.isOrganisation ? 'Contact first name' : 'First name'}
                                </Typography>
                            </label>
                            <TextField
                                id="firstname"
                                name="firstname"
                                className={classes.textField}
                                value={profileStore.firstName}
                                onChange={this.handleFirstNameChange}
                                InputLabelProps={{
                                    shrink: false
                                }}
                                inputProps={{
                                    maxLength: 50
                                }}
                                margin="normal"
                                variant="outlined"
                                error={profileStore.firstNameError}
                                helperText={profileStore.firstNameHelperText}
                                disabled
                            />
                        </Grid>
                        <LastNameGridContainer isOrganisation={profileStore.isOrganisation} className={classes.lastPositionForm}>
                            <label htmlFor="lastname">
                                <Typography variant="h5" color="secondary" className={[classes.subtitle2, classes.formLabel].join(' ')}>
                                    {profileStore.isOrganisation ? 'Contact last name' : 'Last name'}
                                </Typography>
                            </label>
                            <TextField
                                id="lastname"
                                className={classes.textField}
                                value={profileStore.lastName}
                                onChange={this.handleLastNameChange}
                                InputLabelProps={{
                                    shrink: false
                                }}
                                inputProps={{
                                    maxLength: 25
                                }}
                                margin="normal"
                                variant="outlined"
                                error={profileStore.lastNameError}
                                helperText={profileStore.lastNameHelperText}
                                disabled
                            />
                        </LastNameGridContainer>
                        <Grid item xs={12} md={6}>
                            <label htmlFor="emailaddress">
                                <Typography variant="h5" color="secondary" className={[classes.subtitle2, classes.formLabel].join(' ')}>
                                    Email address
                                </Typography>
                            </label>
                            <TextField
                                id="emailaddress"
                                className={classes.textField}
                                value={profileStore.emailAddress}
                                onChange={this.handleEmailAddressChange}
                                InputLabelProps={{
                                    shrink: false
                                }}
                                inputProps={{
                                    maxLength: 320
                                }}
                                margin="normal"
                                variant="outlined"
                                error={profileStore.emailAddressError}
                                helperText={profileStore.emailAddressHelperText}
                                disabled
                            />
                        </Grid>
                    </Grid>
                    {
                        !profileStore.isOrganisation && <Grid item md={6} />
                    }
                    {
                        profileStore.isOrganisation &&
                        <Grid item xs={12}>
                            <FormControl
                                component="fieldset"
                                aria-labelledby="lblIsAuthorisedBehalfOfOrg"
                                margin="normal"
                                required
                                error={profileStore.isAuthorisedBehalfOfOrgError}
                            >
                                <FormLabel component="legend" className={classes.hiddenLabel}></FormLabel>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={profileStore.isAuthorisedBehalfOfOrg}
                                            onChange={this.handleIsAuthorisedBehalfOfOrgChange}
                                        />
                                    }
                                    label={<Typography variant="subtitle2"
                                    name="lblIsAuthorisedBehalfOfOrg">{"By ticking this box, you confirm that you are authorised to make this submission on behalf of your organisation"}</Typography>}
                                />
                                <FormHelperText>{profileStore.isAuthorisedBehalfOfOrgHelperText}</FormHelperText>
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <hr className={classes.hr} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <label htmlFor="addressLine1">
                            <Typography variant="h5" color="secondary" className={[classes.subtitle2, classes.formLabel].join(' ')}>
                                Address line 1
                            </Typography>
                        </label>
                        <TextField
                            id="addressLine1"
                            name="AddressLine1"
                            className={classes.textField}
                            placeholder={profileStore.addressLine1 || this.inputFocusName === "AddressLine1" || this.hasAutoFilledEnabled ? "" : "Address line 1"}
                            value={profileStore.addressLine1}
                            onChange={this.handleAddressLine1Change}
                            onFocus={this.handleOnFocusInput}
                            onBlur={this.handleOnBlurInput}
                            onAnimationStart={this.handleAutoFill}
                            InputLabelProps={{
                                shrink: false
                            }}
                            inputProps={{
                                maxLength: 250
                            }}
                            margin="normal"
                            variant="outlined"
                            error={profileStore.addressLine1Error}
                            helperText={profileStore.addressLine1HelperText}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <label htmlFor="addressLine2">
                            <Typography variant="h5" color="secondary" className={[classes.subtitle2, classes.formLabel].join(' ')}>
                                Address line 2 (optional)
                            </Typography>
                        </label>
                        <TextField
                            id="addressLine2"
                            name="AddressLine2"
                            className={classes.textField}
                            placeholder={profileStore.addressLine2 || this.inputFocusName === "AddressLine2" || this.hasAutoFilledEnabled ? "" : "Address line 2"}
                            value={profileStore.addressLine2}
                            onChange={this.handleAddressLine2Change}
                            onFocus={this.handleOnFocusInput}
                            onBlur={this.handleOnBlurInput}
                            onAnimationStart={this.handleAutoFill}
                            InputLabelProps={{
                                shrink: false
                            }}
                            inputProps={{
                                maxLength: 250
                            }}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <label htmlFor="suburb">
                            <Typography variant="h5" color="secondary" className={[classes.subtitle2, classes.formLabel].join(' ')}>Suburb</Typography>
                        </label>
                        <TextField
                            id="suburb"
                            name="Suburb"
                            className={classes.textField}
                            placeholder={profileStore.suburb || this.inputFocusName === "Suburb" || this.hasAutoFilledEnabled ? "" : "Suburb"}
                            value={profileStore.suburb}
                            onChange={this.handleSuburbChange}
                            onFocus={this.handleOnFocusInput}
                            onBlur={this.handleOnBlurInput}
                            onAnimationStart={this.handleAutoFill}
                            InputLabelProps={{
                                shrink: false
                            }}
                            inputProps={{
                                maxLength: 21
                            }}
                            margin="normal"
                            variant="outlined"
                            error={profileStore.suburbError}
                            helperText={profileStore.suburbHelperText}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <label htmlFor="postcode">
                            <Typography variant="h5" color="secondary" className={[classes.subtitle2, classes.formLabel].join(' ')}>Postcode</Typography>
                        </label>
                        <TextField
                            id="postcode"
                            name="Postcode"
                            className={classes.textField}
                            placeholder={profileStore.postcode || this.inputFocusName === "Postcode" || this.hasAutoFilledEnabled ? "" : "Postcode"}
                            value={profileStore.postcode}
                            onChange={this.handlePostcodeChange}
                            onFocus={this.handleOnFocusInput}
                            onBlur={this.handleOnBlurInput}
                            onAnimationStart={this.handleAutoFill}
                            InputLabelProps={{
                                shrink: false
                            }}
                            inputProps={{
                                maxLength: 4
                            }}
                            margin="normal"
                            variant="outlined"
                            error={profileStore.postcodeError}
                            helperText={profileStore.postcodeHelperText}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            id="stateOrTerritory"
                            shrink={false} className={[classes.subtitle2, classes.formLabel].join(' ')}>State or territory</InputLabel>
                        <FormControl
                            className={classes.selectField}
                            variant="outlined"
                            margin="normal"
                            error={profileStore.stateError}
                        >
                            <InputLabel shrink={false}>{profileStore.state ? "" : "State or territory"}</InputLabel>
                            <Select
                                labelId="stateOrTerritory"
                                id="stateOrTerritorySelect"
                                value={profileStore.state}
                                onChange={this.handleStateChange}
                                input={
                                    <OutlinedInput
                                        labelWidth={103}
                                    />
                                }
                                inputProps={{
                                    'aria-labelledby': 'stateOrTerritory'
                                }}
                                variant="outlined"
                            >
                                <MenuItem value={'VIC'}>Victoria</MenuItem>
                                <MenuItem value={'ACT'}>Australian Capital Territory</MenuItem>
                                <MenuItem value={'NSW'}>New South Wales</MenuItem>
                                <MenuItem value={'NT'}>Northern Territory</MenuItem>
                                <MenuItem value={'QLD'}>Queensland</MenuItem>
                                <MenuItem value={'SA'}>South Australia</MenuItem>
                                <MenuItem value={'TAS'}>Tasmania</MenuItem>
                                <MenuItem value={'WA'}>Western Australia</MenuItem>
                            </Select>
                            {
                                profileStore.stateHelperText &&
                                <FormHelperText id='state-helper-text'>{profileStore.stateHelperText}</FormHelperText>
                            }
                            <FormHelperText id='state-value' className={classes.stateHelperText}>stateOrTerritory {profileStore.state}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <label htmlFor="phoneNumber">
                            <Typography variant="h5" color="secondary" className={[classes.subtitle2, classes.formLabel].join(' ')}>Phone number</Typography>
                        </label>
                        <TextField
                            id="phoneNumber"
                            name="PhoneNumber"
                            className={classes.textField}
                            placeholder={profileStore.phoneNumber || this.inputFocusName === "PhoneNumber" || this.hasAutoFilledEnabled ? "" : "Phone number"}
                            value={profileStore.phoneNumber}
                            onChange={this.handlePhoneNumberChange}
                            onFocus={this.handleOnFocusInput}
                            onBlur={this.handleOnBlurInput}
                            onAnimationStart={this.handleAutoFill}
                            InputLabelProps={{
                                shrink: false
                            }}
                            inputProps={{
                                maxLength: 10
                            }}
                            margin="normal"
                            variant="outlined"
                            error={profileStore.phoneNumberError}
                            helperText={profileStore.phoneNumberHelperText}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <hr className={classes.hr} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset" margin="normal">
                        <FormLabel component="legend">
                            <Typography variant="h5" color="secondary" className={[classes.subtitle2, classes.formLabel].join(' ')}>Preferred contact</Typography>
                        </FormLabel>
                        <RadioGroup
                            aria-label="Preferred contact"
                            name="preferredContact"
                            value={profileStore.preferredContactMethod}
                            onChange={this.handlePreferredContactMethodChange}
                            row={true}
                        >
                            <FormControlLabel value="1" control={<Radio color="primary" inputProps={{ 'aria-label': 'Email' }} />} label={<Typography variant="subtitle2">Email</Typography>} />
                            <FormControlLabel value="2" control={<Radio color="primary" inputProps={{ 'aria-label': 'Phone' }} />} label={<Typography variant="subtitle2">Phone</Typography>} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Hidden xsUp>   {/* TODO: uncomment when authorisation is implemented */}
                    <Grid item xs={12}>
                        <TextField
                            className={classes.textField}
                            label="Password"
                            type="password"
                            value={profileStore.password}
                            onChange={this.handlePasswordChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                maxLength: 10
                            }}
                            margin="normal"
                            variant="outlined"
                            required
                            error={profileStore.passwordError}
                            helperText={profileStore.passwordHelperText}
                        />
                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    <FormControl
                        component="fieldset"
                        required
                        error={profileStore.agreeToVecPrivacyPolicyError}
                    >
                        <FormLabel component="legend" className={classes.hiddenLabel}></FormLabel>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={profileStore.agreeToVecPrivacyPolicy}
                                    onChange={this.handleAgreeToVecPrivacyPolicyChange}
                                    value={profileStore.agreeToVecPrivacyPolicy ? "1" : "0"}
                                />
                            }
                            label={<Typography variant="subtitle2">{"I agree to the VEC's privacy policy"}</Typography>}
                        />
                        <FormHelperText>{profileStore.agreeToVecPrivacyPolicyHelperText}</FormHelperText>
                    </FormControl>
                    <a href="https://www.vec.vic.gov.au/privacy" target="_blank" rel="noopener noreferrer">
                        <img className={classes.privacyPolicyLink} src={LinkIcon} alt="Privacy policy" />
                    </a>
                </Grid>
                <Grid item xs={12}>
                    <hr className={classes.hr} />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.marginTopGrid}>
                        The Victorian Electoral Commission (VEC) will collect and use any information provided to capture and process your submission. We will also use your email address to add you to a mailing list so we can provide you with review updates and may send you a survey to seek your feedback on the submission tool. You can unsubscribe from this mailing list at any time. Personal information being collected includes your name, address and contact information (phone number and/or email address). Please note that submissions will be made publicly available, although the VEC will take all reasonable steps to remove detailed address information and personal contact information as well as any obscene or offensive content. Your full name (or organisation name) and locality/suburb will be published alongside your submission on the VEC website. Information provided by submitters will be handled in accordance with the VEC’s Privacy Policy.
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.marginTopGrid}>
                    <hr className={classes.hr} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Grid container justifyContent="flex-end">
                        <StyledPrimaryContainedButton color="primary" variant="contained" onClick={() => this.handleSubmitClick()}>
                            Save
                        </StyledPrimaryContainedButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

}

ContactDetailsContent.propTypes = {
    classes: PropTypes.object.isRequired,
    profileStore: PropTypes.object
}

export default compose(
    withStyles(styles)
)(ContactDetailsContent);
