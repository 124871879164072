var React = require('react');

function IconChevronDown (props) {
    return React.createElement("svg",props,React.createElement("path",{"fill":"#A30134","fillRule":"nonzero","stroke":"#A30134","d":"M7.02 7c-.04 0-.123 0-.165-.04L1.062 1.38a.188.188 0 0 1 0-.28.205.205 0 0 1 .29 0L7.02 6.523l5.627-5.462a.205.205 0 0 1 .29 0c.083.08.083.2 0 .279L7.145 6.92c0 .08-.083.08-.124.08z"}));
}

IconChevronDown.defaultProps = {"width":"14","height":"8","viewBox":"0 0 14 8"};

module.exports = IconChevronDown;

IconChevronDown.default = IconChevronDown;
