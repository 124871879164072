import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles'
import { inject, observer } from 'mobx-react';
import { Enums } from '../../../enums'
import SubmissionsSummaryStage1 from './submissions-summary-stage1';
import SubmissionsSummaryStage2 from './submissions-summary-stage2';
import SubmissionsSummaryStage3 from './submissions-summary-stage3';

const styles = theme => ({
    titleContainer: {
        paddingTop: "30px",
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "20px"
        }
    },
    lastPositionStage: {
        order: 1
    },
    headerStyle: {
        textAlign: "center",
        paddingTop: "30px",
    }
});

@inject('authStore', 'submissionStore', 'reviewStore','routerStore')
@observer
class SubmissionsSummary extends React.Component {

    constructor(props) {
        super(props);
        this.mySubmissionRef = React.createRef();
    }

    
    componentDidMount(){
        this.goToMySubmissions();
    }

    componentDidUpdate(){
       this.goToMySubmissions();
    }

    componentWillUnmount(){
        const { submissionStore } = this.props;
        submissionStore.scrollToSubmissions= false;
    }

    goToMySubmissions = () => {
        const { submissionStore } = this.props;
        if (this.props.location && this.props.location.state &&
            this.props.location.state.fromSubmission) {
            setTimeout(() => {
                this.mySubmissionRef.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                })
            }, 10);
            let state = { ...this.props.location.state };
            delete state.fromSubmission;
            this.props.routerStore.history.replace('/', state);
        }
        if(submissionStore.scrollToSubmissions) {
            setTimeout(() => {
                this.mySubmissionRef.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                })
            }, 10);
        }
    }

    showDrafts(stage) {
        const { authStore, submissionStore, reviewStore } = this.props;
        const { currentSubmissions } = submissionStore;
        return authStore.isUserAuthenticated && submissionStore.hasDraftSubmission(currentSubmissions, stage, reviewStore.reviewDetails.reviewType);
    }

    showCompleted(stage) {
        const { authStore, submissionStore, reviewStore } = this.props;
        const { currentSubmissions } = submissionStore;
        return authStore.isUserAuthenticated && submissionStore.hasCompletedSubmissions(currentSubmissions, stage, reviewStore.reviewDetails.reviewType);
    }

    render() {
        const { classes, reviewStore: {isPreliminarySubmissionsOpen, isPreliminarySubmissionsActive, isResponseSubmissionsOpen, isResponseSubmissionsActive, isAddendumSubmissionsOpen, isAddendumSubmissionsActive } } = this.props;
        const showDraftsStage1 = this.showDrafts(Enums.SubmissionStage.Preliminary) && isPreliminarySubmissionsActive;
        const showDraftsStage2 = this.showDrafts(Enums.SubmissionStage.Response) && isResponseSubmissionsActive;
        const showDraftsStage3 = this.showDrafts(Enums.SubmissionStage.Addendum) && isAddendumSubmissionsActive;
        const showCompletedStage1 = this.showCompleted(Enums.SubmissionStage.Preliminary) && isPreliminarySubmissionsOpen;
        const showCompletedStage2 = this.showCompleted(Enums.SubmissionStage.Response) && isResponseSubmissionsOpen;
        const showCompletedStage3 = this.showCompleted(Enums.SubmissionStage.Addendum) && isAddendumSubmissionsOpen;

        return (
            <Grid id="My_Submissions" ref={ref => { this.mySubmissionRef = ref }} >
                {(showDraftsStage2 || showCompletedStage2 || showCompletedStage1 || showDraftsStage1 || showDraftsStage3 || showCompletedStage3) &&
                    <Grid className={classes.headerStyle}>
                        <Typography variant="h1" color="secondary">My submissions</Typography>
                    </Grid>}
                {isAddendumSubmissionsActive ?
                    <React.Fragment>
                        <SubmissionsSummaryStage3
                            showDraftsStage3={showDraftsStage3}
                            showCompletedStage3={showCompletedStage3} />
                        <SubmissionsSummaryStage2
                            showDraftsStage2={showDraftsStage2}
                            showCompletedStage2={showCompletedStage2} />
                        <SubmissionsSummaryStage1
                            showDraftsStage1={showDraftsStage1}
                            showCompletedStage1={showCompletedStage1}
                            isProposedBoundariesPublished={isResponseSubmissionsOpen} />
                    </React.Fragment>
                :
                isResponseSubmissionsActive ?
                    <React.Fragment>
                        <SubmissionsSummaryStage2
                            showDraftsStage2={showDraftsStage2}
                            showCompletedStage2={showCompletedStage2} />
                        <SubmissionsSummaryStage1
                            showDraftsStage1={showDraftsStage1}
                            showCompletedStage1={showCompletedStage1}
                            isProposedBoundariesPublished={isResponseSubmissionsOpen} />
                         <SubmissionsSummaryStage3
                            showDraftsStage3={showDraftsStage3}
                            showCompletedStage3={showCompletedStage3} />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <SubmissionsSummaryStage1
                            showDraftsStage1={showDraftsStage1}
                            showCompletedStage1={showCompletedStage1}
                            isProposedBoundariesPublished={isResponseSubmissionsOpen} />
                        <SubmissionsSummaryStage2
                            showDraftsStage2={showDraftsStage2}
                            showCompletedStage2={showCompletedStage2} />
                        <SubmissionsSummaryStage3
                            showDraftsStage3={showDraftsStage3}
                            showCompletedStage3={showCompletedStage3} />
                    </React.Fragment>}
            </Grid>
        );
    }

}

SubmissionsSummary.propTypes = {
    classes: PropTypes.object,
    submissionStore: PropTypes.object,
    authStore: PropTypes.object,
    reviewStore: PropTypes.object,   
    location: PropTypes.object,
    routerStore: PropTypes.object
};

export default compose(
    withRouter,
    withStyles(styles)
)(SubmissionsSummary);