import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Hidden, Grid, Button, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import { observable, computed } from 'mobx';
import SegmentListReadOnly from "./segment-list-readonly";
import IconArrowUp from '-!svg-react-loader?name=IconPencil!../../../static/images/icon-arrow-up.svg';
import IconArrowDown from '-!svg-react-loader?name=IconPencil!../../../static/images/icon-arrow-down.svg';

const styles = theme => ({
    districtList: {
        overflowX: "hidden",
        overflowY: "auto",
        maxHeight: "400px",
        marginBottom: '30px'
    },
    sectionHeaderText: {
        fontWeight: 'bold',
        marginLeft: '15px',
        marginTop: '24px'
    },
    headerContainer: {
        background: theme.palette.primary.dark,
        height: '39px'
    },
    segmentTableCell: {
        padding: '0px',
        verticalAlign: 'middle'
    },
    districtsHeader: {
        width: '30%',
        maxWidth: '200px',
        textAlign: 'left'
    },
    councillorHeader: {
        width: '20%',
        textAlign: 'center'
    },
    votersHeader: {
        width: '25%',
        textAlign: 'right'
    },
    deviationHeader: {
        width: '25%',
        textAlign: 'right'
    },
    districtsHeaderText: {
        marginLeft: '15px'
    },
    segmentTableHeadingText: {
        fontWeight: '600',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        color: theme.palette.white
    },
    segmentTableHeadingContainer: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    sortingButton: {
        minWidth: '10px',
        heigth: '10px'
    }
});

@observer
class ExistingBoundariesInfo extends React.Component {
    constructor(props) {
        super(props);
        this.modelingStore = props.modelingStore;
    }

    @observable sortOrder = 'asc';
    @observable nameColumnSort = 'name';
    @observable sortingState = {};

    @computed get boundariesSorted() {
        const { existingBoundaries } = this.modelingStore;
        return [...existingBoundaries].sortByProperty(this.nameColumnSort, this.sortOrder, "properties");
    }

    @computed get boundariesInViewSorted() {
        const { existingBoundariesInView } = this.modelingStore;
        return [...existingBoundariesInView].sortByProperty(this.nameColumnSort, this.sortOrder, "properties");
    }

    handleTableSorting = (columnSort, nameColumnSort) => {
        let sortingValue = this.sortingState[`columnSort${columnSort}`];
        sortingValue = typeof sortingValue === 'undefined' ? false : !sortingValue;
        Object.assign(this.sortingState, { [`columnSort${columnSort}`]: sortingValue });
        this.nameColumnSort = nameColumnSort;
        this.sortOrder = sortingValue ? 'asc' : 'desc';

        Object.keys(this.sortingState).forEach(function (key) {
            if (key.startsWith('columnSort') && key !== `columnSort${columnSort}`) {
                Object.assign(this.sortingState, { [key]: true });
            }
        }.bind(this));
    }

    render() {
        const { classes } = this.props;

        return (
            <Hidden smDown>
                <Grid container className={classes.headerContainer}>
                    <Grid className={[classes.segmentTableCell, classes.districtsHeader].join(' ')}>
                        <Grid className={classes.segmentTableHeadingContainer}>
                            <Typography variant={"body1"} className={[classes.segmentTableHeadingText, classes.districtsHeaderText].join(' ')}>
                                Wards
                            </Typography>
                            <Button className={classes.sortingButton} onClick={() => this.handleTableSorting(1, 'name')}>
                                {this.sortingState['columnSort1'] || typeof this.sortingState['columnSort1'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid className={[classes.segmentTableCell, classes.councillorHeader].join(' ')}>
                        <Grid className={classes.segmentTableHeadingContainer}>
                            <Typography variant={"body1"} className={classes.segmentTableHeadingText}>
                                Councillors
                            </Typography>
                            <Button className={classes.sortingButton} onClick={() => this.handleTableSorting(2, 'members')}>
                                {this.sortingState['columnSort2'] || typeof this.sortingState['columnSort2'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid className={[classes.segmentTableCell, classes.votersHeader].join(' ')}>
                        <Grid className={classes.segmentTableHeadingContainer}>
                            <Typography variant={"body1"} className={classes.segmentTableHeadingText}>
                                Voters
                            </Typography>
                            <Button className={classes.sortingButton} onClick={() => this.handleTableSorting(3, 'electors')}>
                                {this.sortingState['columnSort3'] || typeof this.sortingState['columnSort3'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid className={[classes.segmentTableCell, classes.deviationHeader].join(' ')}>
                        <Grid className={classes.segmentTableHeadingContainer}>
                            <Typography variant={"body1"} className={classes.segmentTableHeadingText}>
                                Deviation
                            </Typography>
                            <Button className={classes.sortingButton} onClick={() => this.handleTableSorting(4, 'deviation')}>
                                {this.sortingState['columnSort4'] || typeof this.sortingState['columnSort4'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.districtList}>
                    {this.boundariesInViewSorted.length > 0 &&
                        <Fragment>
                            <Typography color={"secondary"} variant={"body1"} className={classes.sectionHeaderText} >
                                CURRENTLY IN VIEW
                            </Typography>

                            <SegmentListReadOnly segments={this.boundariesInViewSorted} />
                        </Fragment>
                    }

                    {this.boundariesSorted.length > 0 &&
                        <Fragment>
                            <Typography color={"secondary"} variant={"body1"} className={classes.sectionHeaderText} >
                                ALL
                            </Typography>

                            <SegmentListReadOnly segments={this.boundariesSorted} />
                        </Fragment>
                    }
                </Grid>
            </Hidden>
        )
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        parentElement: PropTypes.object,
        modelingStore: PropTypes.object
    }
}

export default withStyles(styles)(ExistingBoundariesInfo)
