import React from 'react'
import PropTypes from 'prop-types';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, Table, TableRow, TableCell, TableBody} from '@material-ui/core';
import IconPlus from '-!svg-react-loader?name=IconPlus!../../../static/images/icon-plus-2.svg';
import IconArrowUp from '-!svg-react-loader?name=IconPencil!../../../static/images/icon-arrow-up.svg';
import IconArrowDown from '-!svg-react-loader?name=IconPencil!../../../static/images/icon-arrow-down.svg';
import { inject, observer } from 'mobx-react';
import StyledPrimaryContainedButton from '../styled-primary-contained-button';
import StyledPrimaryOutlinedButton from '../styled-primary-outlined-button';
import { observable, computed } from 'mobx';
import SegmentList from './segment-list';
import UnassignedInfoRow from './unassigned-info-row';
import MoreInfoTooltip from '../reviews/submission/more-info-tooltip';
import { Enums } from '../../enums';
import InfoIcon from '../../../static/images/info_icon.svg'
import IconPlusBlack from '-!svg-react-loader?name=IconPlus!../../../static/images/icon-plus.svg';
import IconMinus from '-!svg-react-loader?name=IconMinus!../../../static/images/icon-minus.svg';

const styles = theme => ({
    segmentMenu: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '@media print': {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            pageBreakBefore: 'always'
        }
    },
    titlePane: {
        background: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
    },
    segmentList: {
        color: theme.palette.primary.contrastText,
        fontSize: '16pt',
        fontWeight: 'bold',
        lineHeight: 1.21,
        overflowX: 'hidden',
        overflowY: 'auto',
        '@media print': {
            overflowX: 'visible',
            overflowY: 'visible'
        }
    },
    finishMapContainer: {
        background: theme.palette.primary.main,
        minHeight: '110px',
        textAlign: 'right',
        alignItems: 'center',
        padding: '25px',
        '@media print': {
            display: 'none'
        }
    },
    segmentTableCell: {
        padding: '0px',
        verticalAlign: 'middle'
    },
    segmentName: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100px',
        paddingTop: '8px',
        textTransform: 'capitalize'
    },
    numberTableCell: {
        textAlign: 'left',
    },
    submitButton: {
        width: '165px',
        minWidth: '165px',
        height: '50px',
        background: theme.palette.white,
        color: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.gray.gray600
        }
    },
    noWrap: {
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap !important'
    },
    titleText: {
        color: theme.palette.white,
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: '0.4px'
    },
    titleContainer: {
        height: '40px',
        padding: '10px 25px',
        marginBottom: '30px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    checkMapButton:{
        color: theme.palette.white,
        borderColor: theme.palette.white,
        '@media print': {
            display: 'none'
        }
    },
    searchContainer: {
        height: '31px',
        marginLeft: '25px',
        display: 'inline-flex'
    },
    searchDistrictsText: {
        color: 'rgba(255, 255, 255, 0.5)'
    },
    headerContainer: {
        background: theme.palette.primary.dark,
        height: '39px',
        [theme.breakpoints.up('sm')]: {
            width: '99%'
        },
        [theme.breakpoints.up('lg')]: {
            width: '95%'
        }
    },
    segmentTableHeadingText: {
        fontWeight: '600',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        color: theme.palette.white,
        '@media print': {
           paddingBottom:'30px',
        }
    },
    districtsHeader: {
        width: '32%',
        maxWidth: '200px'
    },
    councillorHeader: {
        width: '30%'
    },
    votersHeader: {
        width: '17%'
    },
    deviationHeader: {
        width: '21%'
    },
    districtsHeaderText: {
        marginLeft: '25px'
    },
    newDistrictContainer: {
        background: '#E7EBF0',
        minHeight: '80px',
        alignItems: 'center',
        padding: '25px'
    },
    numberDistrictsText: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px'
    },
    addNewSegmentText: {
        fontWeight: 'bold'
    },
    saveButton: {
        width: '165px',
        minWidth: '165px',
        heigth: '50px',
        borderColor: theme.palette.white,
        color: theme.palette.white,
        background: theme.palette.primary.main,
        marginBottom: '0px'
    },
    saveContainer: {
        textAlign: 'left'
    },
    submitContainer: {
        textAlign: 'right'
    },
    numberDistrictsContainer: {
        display: 'inline-flex'
    },
    addNewSegmentContainer: {
        textAlign: 'right'
    },
    districtsText: {
        marginLeft: '5px',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
    },
    iconPlus: {
        marginLeft: '15px'
    },
    segmentTableHeadingContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        '@media print': {
            paddingRight:'15px',
        }
    },
    sortingButton: {
        minWidth: '10px',
        height: '10px',
        '@media print': {
            display: 'none !important'
        }
    },
    addNewDistrictButton: {
        '&:disabled': {
            opacity: 0.3
        }
    },
    inViewHeaderText: {
        fontWeight: 'bold', 
        marginLeft: '25px', 
        marginTop: '24px'
    },
    allHeaderText: {
        fontWeight: 'bold', 
        marginLeft: '25px', 
        marginTop: '24px'
    },
    numMembersBox: {
        borderColor: theme.palette.secondary.main,
        borderStyle: 'solid',
        borderWidth: '1px',
        background: 'white',
        borderRadius: '2px',
        width: '36px',
        height: '33px',
        padding: '5px',        
    },
    primaryIcon: {
        '& > path': {
            fill: theme.palette.secondary.main + ' !important',
        },
        '@media print': {
            display: 'none !important'
        }
    },
    disabledIcon: {
        '& > path': {
            fill: 'rgba(0, 0, 0, 0.25) !important',
        },
        '@media print': {
            display: 'none !important'
        }
    },
    alignCenter: {
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    clickableIcon: {
        cursor: 'pointer',
        paddingLeft: '4px',
        '@media print': {
            display: 'none !important'
        }
    },
    buttonPadding:{
        paddingLeft:'4px',
        paddingRight:'4px'
    },
    alignRight: {
        justifyContent: 'right',
        textAlign: 'right',
        alignItems: 'right',
        display: 'flex'
    },
    toolTip:{
        cursor:'pointer',
        '@media print': {
            display: 'none !important'
        }
    },
    tableCell: {
        paddingLeft:'10px',
        '@media print': {
            paddingRight:'30px',
        }
    }
});

@inject('msgBoxStore')
@observer
class SegmentInfoPanel extends React.Component {

    constructor(props) {
        super();
        this.modelingStore = props.modelingStore;
        this.reviewStore = props.reviewStore;
        this.scrollElementRef = React.createRef();
        this.scrollElementToAllRef = React.createRef();
    }

    @observable editSegmentName = false;
    @observable colourPickerActive = false;
    @observable colourPickerAnchor = null;
    @observable showDeviationInfo = false;
    @observable sortingState = {};
    @observable sortOrder = 'asc';
    @observable nameColumnSort = 'name';

    @computed get segmentsSorted() {
        const { segments } = this.modelingStore;
        let segmentsSort = [...segments].sortByProperty(this.nameColumnSort, this.sortOrder, "properties");
        const newDistricts = segmentsSort.filter(seg => seg.properties.name === "New Ward");
        // New districts should be positioned on top
        if(newDistricts.length > 0) {

            for(const seg of newDistricts){
                const indexNewDistrict = segmentsSort.findIndex(s => s.properties.id === seg.properties.id);

                if(indexNewDistrict > 0) {
                    const newDistrict = {...segmentsSort[indexNewDistrict]};
                    segmentsSort.splice(indexNewDistrict, 1);
                    segmentsSort.splice(0, 0, newDistrict);
                }
            }
        }

        return segmentsSort;
    }

    @computed get segmentsInViewSorted() {
        const { segmentsInView } = this.modelingStore;
        return [...segmentsInView].sortByProperty(this.nameColumnSort, this.sortOrder, "properties");
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        modelingStore: PropTypes.object,
        reviewStore: PropTypes.object,
        finishBtnFn: PropTypes.func,
        saveBtnFn: PropTypes.func
    }

    render() {

        if (this.props.reviewStore.reviewDetails == null) {
            const query = new URLSearchParams(window.location.search);
            let councilId = query.get("councilId");
            window.location.replace('/council/' + councilId);
        }

        const { classes } = this.props;
        const { unassignedSegmentMembers, totalReviewMembers, segments } = this.modelingStore;
        const { reviewStore, handleCheckMyMapClicked } = this.props;
        const allowEditing = (reviewStore.reviewDetails.reviewType == Enums.ReviewType.Full || reviewStore.reviewDetails.reviewType == Enums.ReviewType.ResponseStage);
        return (
            <div className={classes.segmentMenu}>
                <Grid className={classes.titlePane}>
                    <Grid container className={classes.titleContainer}>
                        <Typography variant={'h3'} className={classes.titleText}>MY WARDS</Typography>
                        <Button variant="outlined" className={classes.checkMapButton} onClick={handleCheckMyMapClicked}>Check my map</Button>
                    </Grid>
                    <Grid container className={classes.headerContainer}>
                        <Grid className={[classes.segmentTableCell, classes.districtsHeader].join(' ')}>
                            <Grid className={classes.segmentTableHeadingContainer}>
                                <Typography variant={"body1"} className={[classes.segmentTableHeadingText, classes.districtsHeaderText].join(' ')}>
                                    Wards
                                </Typography>
                                <Button className={classes.sortingButton} onClick={() => this.handleTableSorting(1, 'name')} id='btnSortDistrict' aria-label='Sort by Ward'>
                                    {this.sortingState['columnSort1'] || typeof this.sortingState['columnSort1'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid className={[classes.segmentTableCell, classes.numberTableCell, classes.councillorHeader, classes.alignRight].join(' ')}>
                            <Grid className={classes.segmentTableHeadingContainer}>
                                <Typography variant={"body1"} className={classes.segmentTableHeadingText}>
                                    Councillors
                                </Typography>
                               <span style={{minHeight:'50px', paddingRight:'5px'}}>&nbsp;</span>
                               {(reviewStore.reviewDetails.reviewType != Enums.ReviewType.WardBoundary) &&<MoreInfoTooltip
                                    title="A council can have from five (5) to twelve (12) councillors. Ensure all councillors have been assigned to wards before completing your submission."
                                >
                                    <img src={InfoIcon} className={classes.toolTip} alt="more info" />
                                </MoreInfoTooltip>}
                            </Grid>
                        </Grid>
                        <Grid className={[classes.segmentTableCell, classes.numberTableCell, classes.votersHeader, classes.alignRight].join(' ')}>
                            <Grid className={classes.segmentTableHeadingContainer} style={{paddingRight:'5px'}}>
                                <Typography variant={"body1"} className={classes.segmentTableHeadingText} style={{'@media print': {paddingRight:'30px'}}}>
                                    Voters
                                </Typography>
                                <Button className={[classes.sortingButton, classes.buttonPadding].join(' ')} onClick={() => this.handleTableSorting(2, 'electors')} id='btnSortElectors' aria-label='Sort by Electors'>
                                    {this.sortingState['columnSort2'] || typeof this.sortingState['columnSort2'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid className={[classes.segmentTableCell, classes.numberTableCell, classes.deviationHeader].join(' ')}>
                            <Grid className={classes.segmentTableHeadingContainer}>
                                <Typography variant={"body1"} className={classes.segmentTableHeadingText}>
                                    Deviation
                                </Typography>
                                <Button className={[classes.sortingButton, classes.buttonPadding].join(' ')} onClick={() => this.handleTableSorting(3, 'deviation')} id='btnSortDeviation' aria-label='Sort by Deviation'>
                                    {this.sortingState['columnSort3'] || typeof this.sortingState['columnSort3'] === 'undefined' ? <IconArrowUp /> : <IconArrowDown />}
                                </Button>
                                <MoreInfoTooltip
                                    title="The number of voters per councillor in each ward should be within the +/-10% tolerance. Sometimes there are reasons to set a ward higher than 10% or lower than -10%, but only when it will move within the tolerance by the time of the election. You can modify the deviation by adjusting the number of councillors and/or voters that fall within a ward."
                                >
                                    <img src={InfoIcon} className={classes.toolTip} alt="more info" />
                                </MoreInfoTooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className={classes.segmentList}>
                    <Grid item xs={12} ref={this.scrollElementRef}>
                        <UnassignedInfoRow />
                        {this.segmentsInViewSorted.length > 0 &&
                            <Typography color={"secondary"} variant={"body1"} className={classes.inViewHeaderText} >
                                CURRENTLY IN VIEW
                            </Typography>
                        }
                        <SegmentList segments={this.segmentsInViewSorted} keyPrefix={'inviewSegment'} idProperty={"id"} allowEditing={true} allowSelection={true} />
                        <Grid ref={this.scrollElementToAllRef}>
                            {this.segmentsInViewSorted.length > 0 &&
                                <Typography color={"secondary"} variant={"body1"} className={classes.allHeaderText} >
                                    ALL
                            </Typography>
                            }
                            <SegmentList segments={this.segmentsSorted} keyPrefix={'segment'} idProperty={"id"} allowEditing={true} allowSelection={true} />
                        </Grid>
                    </Grid>
                </Grid>
                <Table>
                    <colgroup>
                        <col className={[classes.tableHeader].join(' ')} style={{width:'38%'}} />
                        <col className={[classes.tableHeader].join(' ')} style={{width:'26%'}} />
                        <col className={[classes.tableHeader].join(' ')} />
                    </colgroup>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{paddingLeft:'10px'}}>
                                <Typography color='secondary' variant='h4' className={classes.numberDistrictsText}>
                                    Total councillors
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2} style={{ paddingLeft: (this.reviewStore.reviewDetails.reviewType == Enums.ReviewType.WardBoundary) ? "13px" : '0px' }}>
                                    {(this.reviewStore.reviewDetails.reviewType != Enums.ReviewType.WardBoundary) && <Grid style={{paddingRight:'4px'}} item>
                                        <IconMinus className={[classes.clickableIcon, (totalReviewMembers == 5 || unassignedSegmentMembers == 0) ? classes.disabledIcon : classes.primaryIcon].join(' ')} onClick={() => this.modelingStore.removeTotalMember()} />
                                    </Grid>}
                                    {(this.reviewStore.reviewDetails.reviewType != Enums.ReviewType.WardBoundary) && <Grid style={{padding:'0px'}} item className={classes.numMembersBox} container alignItems={'center'} justifyContent="center">
                                        <Typography color={'textPrimary'} className={classes.alignCenter} style={{marginTop:'4px'}}>
                                            {totalReviewMembers}
                                        </Typography>
                                    </Grid>}
                                    {(this.reviewStore.reviewDetails.reviewType == Enums.ReviewType.WardBoundary) && <Typography color={'textPrimary'}>
                                                        {totalReviewMembers}
                                                    </Typography>
                                    }
                                    {(this.reviewStore.reviewDetails.reviewType != Enums.ReviewType.WardBoundary) && <Grid style={{paddingLeft:'0px'}} item>
                                        <IconPlusBlack className={[classes.clickableIcon, totalReviewMembers < 12 ? classes.primaryIcon: classes.disabledIcon].join(' ')} onClick={() => {this.modelingStore.addTotalMember()}}/>
                                    </Grid>}
                                </Grid>
                            </TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Grid container className={classes.newDistrictContainer}>
                    <Grid item xs={6} className={classes.numberDistrictsContainer}>
                        <Typography color='secondary' variant='h4' className={classes.numberDistrictsText}>
                            {segments.length}
                        </Typography>
                        <Typography color='secondary' variant='h5' className={classes.districtsText}>
                            Wards
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.addNewSegmentContainer}>
                        {allowEditing && <Button className={classes.addNewDistrictButton} disabled={unassignedSegmentMembers == 0} onClick={() => { this.addSegment() }}>
                            <Typography color='secondary' variant="subtitle2" className={classes.addNewSegmentText}>
                                Add Ward
                            </Typography>
                            <IconPlus className={classes.iconPlus} />
                        </Button>}
                    </Grid>
                </Grid>
                <Grid container className={classes.finishMapContainer}>
                    <Grid item xs={6} className={classes.saveContainer}>
                        <StyledPrimaryOutlinedButton className={classes.saveButton} onClick={() => { this.saveBtnClicked() }}>
                            Save
                        </StyledPrimaryOutlinedButton>
                    </Grid>
                    <Grid item xs={6} className={classes.submitContainer}>
                        <StyledPrimaryContainedButton className={classes.submitButton} onClick={() => { this.finishBtnClicked() }}>
                            Submit
                        </StyledPrimaryContainedButton>
                    </Grid>
                </Grid>
            </div>
        )
    }

    addSegment = () => {
        (this.segmentsInViewSorted.length > 0) ?
            this.scrollElementToAllRef.current.scrollIntoView(true) :
            this.scrollElementRef.current.scrollIntoView(true);
        this.modelingStore.addSegment('New Ward');
        this.modelingStore.setIsMapDirty(true);
    }

    finishBtnClicked() {
        if (this.props.finishBtnFn) {
            this.props.finishBtnFn();
        }
    }

    saveBtnClicked() {
        if (this.props.saveBtnFn) {
            this.props.saveBtnFn();
        }
    }

    handleTableSorting = (columnSort, nameColumnSort) => {
        let sortingValue = this.sortingState[`columnSort${columnSort}`];
        sortingValue = typeof sortingValue === 'undefined' ? false : !sortingValue;
        Object.assign(this.sortingState, { [`columnSort${columnSort}`]: sortingValue });
        this.nameColumnSort = nameColumnSort;
        this.sortOrder = sortingValue ? 'asc' : 'desc';

        Object.keys(this.sortingState).forEach(function (key) {
            if (key.startsWith('columnSort') && key !== `columnSort${columnSort}`) {
                Object.assign(this.sortingState, { [key]: true });
            }
        }.bind(this));
    }
}

SegmentInfoPanel.propTypes = {
    msgBoxStore: PropTypes.object,
    handleCheckMyMapClicked: PropTypes.func.isRequired
}

export default compose(
    withStyles(styles)
)(SegmentInfoPanel)