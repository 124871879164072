import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Messages } from '../../../messages';
import SuccessIcon from '../../../../static/images/success-dialog-icon.svg';
import StyledPrimaryOutlinedButton from '../../styled-primary-outlined-button';
import StyledPrimaryContainedButton from '../../styled-primary-contained-button';

const styles = theme => ({
    dialogContainer: {
        height: '400px',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: 'unset',
            height: 'unset',
        }
    },
    imageContainer: {
        textAlign: 'center',
    },
    actionContainer: {
        justifyContent: 'center',
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        lineHeight: '42px',
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        alignItems: 'center',
    },
    content: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal,
        textAlign: 'center',
        alignItems: 'center',
    },
    refNumber: {
        fontWeight: 'bold',
        display: 'inline',
    },
    action1ButtonStyle: {
        float: 'right',
        marginRight: '15px',
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        }
    },
})

@inject('submissionStore', 'routerStore', 'reviewStore')
@observer
class SubmissionCompleteDialog extends React.Component {
    render() {
        const { classes, submissionStore } = this.props;

        return (
            <Dialog
                disableEscapeKeyDown
                open={submissionStore.submissionCompleteDialogOpen}
                onClose={this.handleDialogClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <Grid container className={classes.dialogContainer}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <DialogTitle>
                            {
                                submissionStore.createSubmissionResult ?
                                    <React.Fragment>
                                        <Grid className={classes.imageContainer}><img src={SuccessIcon} alt="Success logo" /></Grid>
                                        <Typography className={classes.title}>Submission successful</Typography>
                                    </React.Fragment> :
                                    <Typography className={classes.title}>
                                        {(submissionStore.saveDraftAction && !submissionStore.redirectToWrittenSubmission) ? 'Save unsuccessful' : 'Submission unsuccessful'}</Typography>
                            }
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText className={classes.content} >
                                {
                                    submissionStore.createSubmissionResult &&
                                    <React.Fragment>{Messages.SubmissionSuccessMessage}<br />
                                    Your reference number is: <strong className={classes.refNumber}>{submissionStore.createSubmissionResult.referenceNumber}</strong>
                                    </React.Fragment>

                                }
                                {
                                    !submissionStore.createSubmissionResult && Messages.SubmissionFailedMessage
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className={classes.actionContainer}>
                            {
                                submissionStore.createSubmissionResult &&
                                <StyledPrimaryContainedButton color="primary" variant="contained" onClick={this.handleDialogOk} autoFocus>
                                    OK
                                </StyledPrimaryContainedButton>
                            }
                            {
                                !submissionStore.createSubmissionResult &&
                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <StyledPrimaryOutlinedButton className={classes.action1ButtonStyle} onClick={this.handleDialogClose} color="primary" variant="outlined">
                                            Cancel
                                        </StyledPrimaryOutlinedButton>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <StyledPrimaryContainedButton onClick={this.handleDialogTryAgain} color="primary" variant="contained">
                                            Try Again
                                        </StyledPrimaryContainedButton>
                                    </Grid>
                                </Grid>
                            }
                        </DialogActions>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Dialog>
        )
    }

    handleDialogClose = (event, reason) => {
        if(reason !== 'backdropClick' && reason !== 'escapeKeyDown'){
            this.props.submissionStore.setSubmissionCompleteDialogOpen(false);
        }
    }

    handleDialogOk = () => {
        const { submissionStore, routerStore, reviewStore } = this.props;
        routerStore.navigateTo(`/council/`+ reviewStore.reviewCouncilId);
        submissionStore.setSubmissionCompleteDialogOpen(false);

        submissionStore.resetSubmissionForm();
        submissionStore.createSubmissionResult = null;
        submissionStore.getSubmissions();
    }

    handleDialogTryAgain = () => {
        const { submissionStore } = this.props;

        if (submissionStore.submittedDraftId) {
            submissionStore.submitDraft(submissionStore.submittedDraftId);
        }
        else {
            submissionStore.saveSubmission(submissionStore.saveDraftAction, submissionStore.redirectToWrittenSubmission);
        }

        submissionStore.setSubmissionCompleteDialogOpen(false);
    }
}

SubmissionCompleteDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    submissionStore: PropTypes.object,
    routerStore: PropTypes.object,
    reviewStore: PropTypes.object
}

export default compose(
    withStyles(styles),
    withWidth(),
)(SubmissionCompleteDialog);