import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import { Messages } from '../../messages';
import ProfileIcon from '../../../static/images/profile.svg';
import OrganisationIcon from '../../../static/images/icon-organisation.svg';
import SettingsIcon from '../../../static/images/settings.svg';
import SubmissionsIcon from '-!svg-react-loader?name=SubmissionsIcon!../../../static/images/icon-enrol.svg';
import SignOutIcon from '../../../static/images/logout.svg';
import ChevronDownIcon from '../../../static/images/down-arrow.svg';
import DragHandleIcon from '../../../static/images/dragHandle.svg';

const styles = theme => ({
    signinDetailsContainer: {
        display: 'inline-block',
        maxWidth: '80%',
        '@media print': {
            display: 'none'
        }
    },
    signedInButtonText: {
        paddingLeft: '15px',
        paddingRight: '15px',
        width: '80%',
        margin: 'auto'
    },
    signedInText: {
        fontSize: '14px',
        fontWeight: '600',
        textTransform: 'none',
    },
    userName: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal,
    },
    menuItemText: {
        color: theme.palette.secondary.charcoal,
        fontWeight: '600',
    },
    submissionsIcon: {
        width: '18px',
        height: '18px',
        '& g > path': {
            stroke: theme.palette.primary.main + ' !important'
        }
    },
    submissionsIconDisabled: {
        width: '18px',
        height: '18px',
        '& g > path': {
            stroke: theme.palette.secondary.charcoal + ' !important'
        }
    },
    dialogTitle: {
        fontWeight: 'bold',
        padding: '16px 24px',
        flex: '0 0 auto',
        margin: '0px'
    },
    linkButton: {
        display: 'inline-block',
        textDecorationLine: 'none !important'
    },
    dragHandleIcon: {
        [theme.breakpoints.down('md')]: {
            marginTop: '9px'
        }
    },
    listIcon: {
        minWidth: '30px !important'
    },
    menu: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
    },
    button: {
        maxWidth: '100%'
    }
});

@inject('rootStore', 'profileStore', 'authStore', 'submissionStore', 'routerStore', 'msgBoxStore', 'reviewStore')
@observer
class SignedInMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null
        };
    }

    render() {
        const { classes, profileStore, submissionStore: { activeSubmissions } } = this.props;
        const { userProfile } = profileStore;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className={classes.signinDetailsContainer}>
                <Hidden mdDown>
                    <Button
                        aria-owns={open ? 'signedInMenu' : undefined}
                        aria-haspopup="true"
                        color="secondary"
                        variant="outlined"
                        onClick={this.handleClick}
                        className={classes.button}
                    >
                        {
                            userProfile.isOrganisation ?
                                <img src={OrganisationIcon} alt="organization" width="31" height="33" /> :
                                <img src={ProfileIcon} alt="profile" width="27" height="33" />
                        }
                        <div className={classes.signedInButtonText}>
                            <Typography className={classes.userName} variant="h2" color="secondary" align="left">
                                {
                                    userProfile.isOrganisation ?
                                        userProfile.organisationName :
                                        `${userProfile.firstName || profileStore.firstName || ''} ${userProfile.lastName || profileStore.lastName || ''}`
                                }
                            </Typography>
                        </div>
                        <img src={ChevronDownIcon} alt="chevron" width="12" height="6" />
                    </Button>
                </Hidden>
                <Hidden lgUp>
                    <Link component="button" className={classes.linkButton} onClick={this.handleClick}>
                        <img src={DragHandleIcon} alt="DragHandleIcon" className={classes.dragHandleIcon} />
                    </Link>
                </Hidden>
                <Popover
                    id="signedInMenu"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <MenuList className={classes.menu}>
                        <MenuItem onClick={this.handleEditProfileClick} disabled={!(userProfile.firstName && userProfile.lastName)}>
                            <ListItemIcon className={classes.listIcon}>
                                <img src={SettingsIcon} color="primary" alt="" width="18" height="18" />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body2"
                                        className={classes.menuItemText}
                                        color="primary"
                                        component="span"
                                    >
                                        Edit my profile
                                    </Typography>
                                }
                            />
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={this.handleMySubmissionsClick} disabled={activeSubmissions.length === 0}>
                            <ListItemIcon className={classes.listIcon}>
                                <SubmissionsIcon className={activeSubmissions.length === 0 ? classes.submissionsIconDisabled : classes.submissionsIcon} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body2"
                                        className={classes.menuItemText}
                                        color="primary"
                                        component="span"
                                    >
                                        My submissions
                                    </Typography>
                                }
                            />
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={this.handleSignOutMenuClick}>
                            <ListItemIcon className={classes.listIcon}>
                                <img src={SignOutIcon} alt="" width="18" height="18" />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography
                                        variant="body2"
                                        className={classes.menuItemText}
                                        color="primary"
                                        component="span"
                                    >
                                        Sign out
                                    </Typography>
                                }
                            />
                        </MenuItem>
                    </MenuList>
                </Popover>
            </div>
        );
    }

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleEditProfileClick = () => {
        const { routerStore, profileStore, reviewStore } = this.props;
        this.handleClose();
        profileStore.loadContactDetailsFormFromUserProfile();
        routerStore.history.push('/profile?councilId=' + reviewStore.reviewCouncilId);
    };

    handleMySubmissionsClick = () => {
        const { routerStore, reviewStore, submissionStore } = this.props;
        this.handleClose();
        submissionStore.scrollToSubmissions = true;
        routerStore.history.push('/council/' + reviewStore.reviewCouncilId);
    };

    handleSignOutMenuClick = () => {
        this.props.msgBoxStore.show({
            content: Messages.SignOutConfirmationMessage,
            header: 'Confirm sign out',
            inputOptions: { type: 'NONE' },
            size: 'xs',
            hideCancel: false,
            confirmButton: 'Yes',
            cancelButton: 'No'
        }).then(() => {
            this.props.authStore.logout();
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });

    };
}

SignedInMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object,
    rootStore: PropTypes.object,
    profileStore: PropTypes.object,
    authStore: PropTypes.object,
    routerStore: PropTypes.object,
    submissionStore: PropTypes.object,
    msgBoxStore: PropTypes.object,
    reviewStore: PropTypes.object
}

export default withStyles(styles)(SignedInMenu);
