import React from 'react';
import { render } from 'react-dom';
import { observer, Provider } from 'mobx-react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import LandingPage from './components/reviews/home/landing-page';
import FormsLayout from './components/reviews/forms-layout';
import ModelingWindow from './components/mapping-tool/modeling-window';
import LoadingSpinner from './components/loading-spinner';
import SessionStore from './stores/session.store';
import VecTheme from './components/vec-theme';
import ScrollToTop from './components/scroll-to-top';
import RouterStoreInitializer from './components/router-store-initializer';
import MsgBox from './components/msg-box';
import './tools';
import PrivateRoute from './components/private-route';

export const stores = new SessionStore();

@observer
class Root extends React.Component {

    render() {
        const { authStore } = stores;

        return (
            <Provider {...stores}>
                <VecTheme>
                    {
                        !authStore.appAuthConfig || authStore.processingLoadConfig ?
                            <LoadingSpinner show={true} /> :
                            <div style={{ height: '100%' }}>
                                <BrowserRouter>
                                    <RouterStoreInitializer>
                                        <React.Fragment>
                                            <ScrollToTop>
                                                <Switch>
                                                    <Route exact path="/mapping/:submissionId?" component={ModelingWindow} />
                                                    <PrivateRoute Component={FormsLayout} path="/profile" />
                                                    <PrivateRoute Component={FormsLayout} path="/writtensubmission/:submissionId?" />
                                                    <Route exact path="/council/:councilId?" component={LandingPage} />
                                                    <Route exact path="/" component={() => {window.location.href = 'https://vec.vic.gov.au'; return null;}} />
                                                    <Route path="*" render={() => <Redirect to="/" />} />
                                                </Switch>
                                            </ScrollToTop>
                                        </React.Fragment>
                                    </RouterStoreInitializer>
                                </BrowserRouter>
                                <LoadingSpinner />
                                <MsgBox />
                            </div>
                    }
                </VecTheme>
            </Provider>
        )
    }
}

render(<Root />, document.getElementById('root'));
