import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Grid, Typography } from '@material-ui/core';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Enums } from '../../../enums';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import ActiveStage1Logo from '../../../../static/images/stage1_active.svg';
import ActiveStage2Logo from '../../../../static/images/stage2_active.svg';
import ActiveStage3Logo from '../../../../static/images/stage3_active.svg';
import ActiveStage4Logo from '../../../../static/images/stage_4_active.svg';
import InActiveStage1Logo from '../../../../static/images/stage1_inactive.svg';
import InActiveStage2Logo from '../../../../static/images/stage2_inactive.svg';
import InActiveStage3Logo from '../../../../static/images/stage3_inactive.svg';
import InActiveStage4Logo from '../../../../static/images/Stage_4_Inactive.svg';
import RedivisionStageDetails from './redivision-stage-details';
import RedivisionStagesSwipe from './redivision-stages-swipe';

const styles = theme => ({
    backgroundContainer: {
        background: theme.palette.secondary.background,
        padding: '20px 0'
    },
    headerStyle: {
        textAlign: 'center'
    }
})

@inject('reviewStore', 'councilStore')
@observer
class RedivisionStages extends React.Component {

    render() {
        const { classes, reviewStore } = this.props;
        const { reviewDetails, getReviewStagesDates, isPreliminarySubmissionsActive, isPreliminarySubmissionsClosed, isResponseSubmissionsActive, isResponseSubmissionsClosed, isAddendumSubmissionsActive, isAddendumSubmissionsClosed } = reviewStore;
        const isWidthUpSm = isWidthUp('sm', this.props.width);
        const preliminarySubmissionsOpenTime = getReviewStagesDates(Enums.MilestoneType.PreliminarySubmissionOpen);
        const preliminarySubmissionsCloseTime = getReviewStagesDates(Enums.MilestoneType.PreliminarySubmissionsClose);
        const responseSubmissionsOpenTime = getReviewStagesDates(Enums.MilestoneType.PreliminaryReportPublished);
        const responseSubmissionsCloseTime = getReviewStagesDates(Enums.MilestoneType.ResponseSubmissionsClose);
        const finalReportPublishedTime = getReviewStagesDates(Enums.MilestoneType.FinalReportPublished);
        const addendumSubmissionsOpenTime = getReviewStagesDates(Enums.MilestoneType.AddendumOpen);
        const addendumSubmissionsCloseTime = getReviewStagesDates(Enums.MilestoneType.AddendumClose);

        return (
            <Grid container className={classes.backgroundContainer} justifyContent="center">
                <Grid item xs={12} sm={10} md={10}>
                    {
                        /* Council name  */
                        isWidthUpSm &&
                        <Grid className={classes.headerStyle}>
                            <Typography variant="h2" color="secondary">Make a submission - {this.props.reviewStore.reviewCouncilName}</Typography>
                        </Grid>
                    }

                    {
                        /* Stages for desktop  */
                        reviewDetails && isWidthUpSm &&
                        <React.Fragment>
                            {reviewDetails.reviewType === Enums.ReviewType.Full ?
                                <RedivisionStageDetails
                                    title='Preliminary submissions'
                                    subTitle={isPreliminarySubmissionsActive ?
                                        <>Open now <br /> Close {preliminarySubmissionsCloseTime.format('ha')} on {preliminarySubmissionsCloseTime.format('D MMMM YYYY')}</> :
                                        (isPreliminarySubmissionsClosed ? <>Closed</> :
                                            <>Open {preliminarySubmissionsOpenTime.format('D MMMM YYYY')} <br /> Close {preliminarySubmissionsCloseTime.format('ha')} on {preliminarySubmissionsCloseTime.format('D MMMM YYYY')}</>)}
                                    imageLogo={isPreliminarySubmissionsActive ? ActiveStage1Logo : InActiveStage1Logo}
                                    content={<>At the start of the review, the Electoral Representation Advisory Panel invites submissions about the local council&apos;s electoral structure.</>} />
                                : ""}
                            <RedivisionStageDetails
                                title='Response submissions'
                                subTitle={isResponseSubmissionsActive ?
                                    <>Open now <br /> Close {responseSubmissionsCloseTime.format('ha')} on {responseSubmissionsCloseTime.format('D MMMM YYYY')}</> :
                                    (isResponseSubmissionsClosed ? <>Closed</> :
                                        <>Open {responseSubmissionsOpenTime.format('D MMMM YYYY')} <br /> Close {responseSubmissionsCloseTime.format('ha')} on {responseSubmissionsCloseTime.format('D MMMM YYYY')}</>)}
                                imageLogo={(reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && isResponseSubmissionsActive ? ActiveStage1Logo : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) ? InActiveStage1Logo : reviewDetails.reviewType === Enums.ReviewType.Full && isResponseSubmissionsActive ? ActiveStage2Logo : InActiveStage2Logo}
                                content={reviewDetails.reviewType === Enums.ReviewType.Full || reviewDetails.reviewType === Enums.ReviewType.ResponseStage ?
                                    <>The Electoral Representation Advisory Panel publishes a preliminary report describing one or more options for the local council&apos;s electoral structure. You can submit a response to the panel&apos;s proposed options.</>
                                    : reviewDetails.reviewType === Enums.ReviewType.WardBoundary ?
                                        <>The review panel publishes a preliminary report describing one or more options for adjusting the ward boundaries of the local council. You can submit a response to the panel&apos;s proposed options.</> : ""} />
                            {addendumSubmissionsOpenTime ?
                                <RedivisionStageDetails
                                    title='Addendum submissions'
                                    subTitle={isAddendumSubmissionsActive ?
                                        <>Open now <br /> Close {addendumSubmissionsCloseTime.format('ha')} on {addendumSubmissionsCloseTime.format('D MMMM YYYY')}</> :
                                        (isAddendumSubmissionsClosed ? <>Closed</> :
                                            <>Open {addendumSubmissionsOpenTime.format('D MMMM YYYY')} <br /> Close {addendumSubmissionsCloseTime.format('ha')} on {addendumSubmissionsCloseTime.format('D MMMM YYYY')}</>)}
                                    imageLogo={reviewDetails.reviewType === Enums.ReviewType.Full && isAddendumSubmissionsActive ? ActiveStage3Logo : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && isAddendumSubmissionsActive ? ActiveStage2Logo : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) ? InActiveStage2Logo : InActiveStage3Logo}
                                    content={reviewDetails.reviewType === Enums.ReviewType.Full || reviewDetails.reviewType === Enums.ReviewType.ResponseStage ?
                                        <>The Electoral Representation Advisory Panel publishes an addendum report describing one or more options for the local council&apos;s electoral structure. You can submit a response to the panel&apos;s proposed options.</>
                                        : reviewDetails.reviewType === Enums.ReviewType.WardBoundary ?
                                            <>The review panel publishes an addendum report describing one or more options for adjusting the ward boundaries of the local council. You can submit a response to the panel&apos;s proposed options.</> : ""} />
                                : ""}
                            <RedivisionStageDetails
                                title='Final report'
                                subTitle={<>{(reviewDetails.reviewType === Enums.ReviewType.Full || reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) ? "" : "Available " }{finalReportPublishedTime.format('D MMMM YYYY')}</>}
                                imageLogo={reviewDetails.reviewType === Enums.ReviewType.Full && addendumSubmissionsOpenTime && isAddendumSubmissionsClosed ? ActiveStage4Logo
                                    : reviewDetails.reviewType === Enums.ReviewType.Full && addendumSubmissionsOpenTime && !isAddendumSubmissionsClosed ? InActiveStage4Logo
                                        : reviewDetails.reviewType === Enums.ReviewType.Full && !addendumSubmissionsOpenTime && isResponseSubmissionsClosed ? ActiveStage3Logo
                                            : reviewDetails.reviewType === Enums.ReviewType.Full && !addendumSubmissionsOpenTime && !isResponseSubmissionsClosed ? InActiveStage3Logo
                                                : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && addendumSubmissionsOpenTime && isAddendumSubmissionsClosed ? ActiveStage3Logo
                                                    : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && addendumSubmissionsOpenTime && !isAddendumSubmissionsClosed ? InActiveStage3Logo
                                                        : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && !addendumSubmissionsOpenTime && isResponseSubmissionsClosed ? ActiveStage2Logo
                                                            : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && !addendumSubmissionsOpenTime && !isResponseSubmissionsClosed ? InActiveStage2Logo : ""}
                                content={reviewDetails.reviewType === Enums.ReviewType.Full || reviewDetails.reviewType === Enums.ReviewType.ResponseStage ?
                                    <>The Electoral Representation Advisory Panel provides a report to the Minister for Local Government with the final recommendation for the local council&apos;s electoral structure. The Minister must consider this recommendation when changing the electoral structure of the local council.</>
                                    : reviewDetails.reviewType === Enums.ReviewType.WardBoundary ?
                                        <>The review panel provides a report to the Minister for Local Government with the final recommendation for adjusting the ward boundaries of the local council. The Minister must consider this recommendation when adjusting the ward boundaries of the local council.</> : ""} />
                        </React.Fragment>
                    }

                    {
                        /* Stages for mobile devices */
                        !isWidthUpSm && <RedivisionStagesSwipe />
                    }
                </Grid>
            </Grid>
        );
    }
}

RedivisionStages.propTypes = {
    classes: PropTypes.object,
    reviewStore: PropTypes.object,
    councilStore: PropTypes.object,
    width: PropTypes.string
};

export default compose(
    withStyles(styles),
    withWidth(),
)(RedivisionStages);
