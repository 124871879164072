import React from 'react'
import PropTypes from 'prop-types';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, TableRow, TableCell, TableBody, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';

const styles = () => ({
    segmentTableCell: {
        padding: '0px',
        verticalAlign: 'middle'
    },
    segmentName: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '116px',
        paddingTop: '8px',
        textTransform: 'capitalize'
    },
    segmentRow: {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        height: '45px'
    },
    withinBoundsDeviation: {
        color: '#438600'
    },
    outOfBoundsDeviation: {
        color: '#ca0000'
    },
    tableHeader: {
        padding: '0px',
        borderBottom: 'none'
    },
    districtsHeader: {
        width: '35%'
    },
    councillorHeader: {
        width: '15%'
    },
    votersHeader: {
        width: '25%'
    },
    deviationHeader: {
        width: '25%'
    },
    segmentNameContainer: {
        marginLeft: '15px',
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap !important'
    },
    districtNameCell: {
        minWidth: '180px'
    },
    tableCellRighAligned: {
        textAlign: 'right',
        paddingRight: '9px',
    },
    tableCellCouncillors: {
        paddingRight: '20px',
    }
});

@inject('msgBoxStore', 'modelingMapStore', 'reviewStore')
@observer
class SegmentListReadOnly extends React.Component {

    constructor(props) {
        super();
        this.modelingStore = props.modelingMapStore;
        this.reviewStore = props.reviewStore;
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        modelingStore: PropTypes.object,
        reviewStore: PropTypes.object,
        finishBtnFn: PropTypes.func,
    }

    render() {
        const { classes, segments } = this.props;
        return <>
            {segments && segments.length > 0 &&
                (<Table>
                    <colgroup>
                        <col className={[classes.tableHeader, classes.districtsHeader].join(' ')} />
                        <col className={[classes.tableHeader, classes.councillorHeader].join(' ')} />
                        <col className={[classes.tableHeader, classes.votersHeader].join(' ')} />
                        <col className={[classes.tableHeader, classes.deviationHeader].join(' ')} />
                    </colgroup>
                    <TableBody>
                        {segments && segments.length > 0 && segments.map((segment, index) => {
                            return (
                                <TableRow className={[classes.segmentRow].join(' ')} key={`${index}`}>
                                    <TableCell className={[classes.segmentTableCell, classes.districtNameCell].join(' ')} >
                                        <Grid item xs={10} className={classes.segmentNameContainer}>
                                            <span className={classes.segmentName}>{segment.properties.name.toLowerCase()}</span>
                                        </Grid>
                                    </TableCell>
                                    <TableCell className={[classes.segmentTableCell, classes.tableCellRighAligned, classes.tableCellCouncillors].join(' ')}>
                                        <Typography color={'textPrimary'}>
                                            {segment.properties.members.toLocaleString()}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={[classes.segmentTableCell, classes.tableCellRighAligned].join(' ')}>
                                        <Typography color={'textPrimary'}>
                                            {segment.properties.electors.toLocaleString()}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={[classes.segmentTableCell, classes.tableCellRighAligned].join(' ')} >
                                        <Typography className={this.deviationClass(segment.properties.deviation)}>
                                            {segment.properties.deviation > 0 && '+'}{segment.properties.deviation.toFixed(2)}%
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>)
            }
        </>
    }

    deviationClass = (deviation) => {
        return (deviation > -10 && deviation < 10) ? this.props.classes.withinBoundsDeviation : this.props.classes.outOfBoundsDeviation;
    }
}

SegmentListReadOnly.propTypes = {
    msgBoxStore: PropTypes.object,
    modelingMapStore: PropTypes.object,
    reviewStore: PropTypes.object,
    segments: PropTypes.any
}

export default compose(
    withStyles(styles)
)(SegmentListReadOnly)
