import 'whatwg-fetch'
import { Tools } from '../tools';
import * as Msal from 'msal';
/* eslint-disable no-unused-vars */
import {unregister} from '../interceptor';

window.msalAuthApp = new Msal.UserAgentApplication('1caf092b-fd8d-4fa8-af15-a8667be9b0a7', 'https://vecadb2c.b2clogin.com/tfp/VECADB2C.onmicrosoft.com/B2C_1_SuSi', null, {})
window.accessToken = null;

class AuthenticationService {
    appAuthConfig = null;
    b2cScopes = null;
    signInAuthority = null;
    redirectUri = null;


    constructor() {
    }

    authCallback(errorDesc, token, error, tokenType) {
        if (error) {
            console.log(error + ": " + errorDesc);
        }
        else {
            console.log("Token type = " + tokenType);
        }
    }

    loadAuthDefaultConfig() {
        let url = '/api/auth/config';
        return fetch(url).then(Tools.getResponseJson);
      
    }

    login = (callback) => {
        window.msalAuthApp.authority = `https://${this.appAuthConfig.authority}/tfp/${this.appAuthConfig.tenant}/${this.appAuthConfig.signInPolicy}`;
      

        return this.loginPopup(callback);
    };

    loginPopup = (policy) => {
        window.msalAuthApp.authority = policy
        return window.msalAuthApp.loginPopup();
      
    }

    register = (callback) => {
        window.msalAuthApp.authority = `https://${this.appAuthConfig.authority}/tfp/${this.appAuthConfig.tenant}/${this.appAuthConfig.signUpPolicy}`;
        this.loginPopup(callback);
    }

   
    isAuthenticated = () => {
        const idToken = this.getIdToken();
        return idToken;
    }

    getIdToken = () => {
        const storage = window.msalAuthApp.cacheStorage.cacheLocation;
        const idToken = `${Msal.Constants.cachePrefix}.idtoken`
        switch (storage) {
            case "sessionStorage":
                this.idToken = sessionStorage.getItem(idToken);
                break;
            case "localStorage":
                this.idToken = localStorage.getItem(idToken);
                break;
            case "default-case":
                this.idToken = null;
                break;
        }
        return this.idToken;
    }

    logout = (councilId) => {
        window.msalAuthApp.config.auth.postLogoutRedirectUri =  window.msalAuthApp.config.auth.postLogoutRedirectUri + '/council/' + councilId;
        window.msalAuthApp.logout();
    };
    
}

const authService = new AuthenticationService();

export default authService;

