import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Link } from '@material-ui/core';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth'
import { observer, inject } from 'mobx-react';

const styles = theme => ({

    footerTextContainer: {
        [theme.breakpoints.up('xs')]: {
            marginTop: '26px',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
        },
        [theme.breakpoints.up('sm')]: {
            margin: '49px 0',
            whiteSpace: 'nowrap',
        }
    },
    signInFooterText: {
        display: 'inline',
        fontSize: '18px',
        fontWeight: 'normal',
        lineHeight: '27px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    },
    signInFooterLink: {
        // link requires negative margin so its text is aligned with footer note
        marginTop: '-3px'
    }
})

@inject('reviewStore')
@observer
class SignInFooter extends React.Component {

    render() {
        const { classes, isMobile, reviewStore: { displaySignInPopup } } = this.props;
        const questionL1Text = 'Already started a submission or made';
        const questionL2Text = 'one before?';
        const linkText = 'Sign in to your account';

        return (
            <Grid>
                {!isMobile &&
                    <Grid className={classes.footerTextContainer} >
                        <Typography id="sign-in-footer-text1" variant={"h5"} className={classes.signInFooterText}>
                            {`${questionL1Text} ${questionL2Text} `}
                        </Typography>
                        <Link id='signInFooterLink' href="#" component='button' variant={"subtitle1"} onClick={() => { displaySignInPopup() }} className={classes.signInFooterLink}>
                            {linkText}
                        </Link>
                    </Grid>
                }
                {isMobile &&
                    <Grid container className={classes.footerTextContainer}>
                        <Typography id="sign-in-footer-text1" variant={"subtitle1"}>
                            {`${questionL1Text} `}
                        </Typography>
                        <Typography id="sign-in-footer-text2" variant={"subtitle1"}>
                            {`${questionL2Text} `}
                        </Typography>
                        <Link id='signInFooterLink' href="#" variant={"subtitle1"} onClick={() => { displaySignInPopup() }}>{linkText}</Link>
                    </Grid>
                }
            </Grid >
        );
    }
}

SignInFooter.propTypes = {
    classes: PropTypes.object,
    reviewStore: PropTypes.object,
    isMobile: PropTypes.bool
};

export default compose(
    withStyles(styles),
    withWidth(),
)(SignInFooter);