import React from 'react'
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { compose } from "recompose";
import Header from '../header';
import ModelingMap from './modeling-map';
import SegmentInfoPanel from './segment-info-panel';
import SelectedBlockPanel from './selected-block-panel';
import SubmissionCompleteDialog from '../reviews/submission/submission-complete-dialog';
import LandingDialog from './landing-dialog';
import MapGuidelinesDialog from './map-guideline/map-guidelines-dialog';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MapTools from './map-tools';
import RouteTransitionGuard from '../route-transition-guard';
import BrowserWarning from '../browser-warning';

const styles = () => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '@media print': {
            display: 'block',
        }
    },
    mapAndWardContainer: {
        flexGrow: 1,
        maxHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
        order: 2,
        '@media print': {
            maxHeight: 'none !important',
            display: 'block',
        }
    },
    mapContainer: {
        flexGrow: 1,
        '@media print': {
            width: '100% !important',
            height: '200mm !important'
        }
    },
    visibleWardInfo: {
        visibility: 'visible',
        transitionTimingFunction: 'ease',
        transition: 'flex-grow 0.2s',
        maxHeight: '100%'
    },
    visibleWardInfoSize: {
        width: '465px'
    },
    invisibleWardInfo: {
        flexBasis: '0%',
        visibility: 'hidden',
        transitionTimingFunction: 'ease',
        transition: 'flex-grow 0.2s',
        maxHeight: '100%'
    }
});

@inject('modelingMapStore', 'rootStore', 'reviewStore', 'submissionStore', 'authStore', 'msgBoxStore')

@observer
class ModelingWindow extends React.Component {

    constructor(props) {
        super(props);
        this.reviewStore = props.reviewStore;
            this.resumedFromDraft = false;
        this.state = {
            wardInfoVisible: false,
            fileMenuVisible: false,
        }
    }

    componentDidMount() {
        const { authStore } = this.props;

        if (!authStore.isUserAuthenticated) {
            this.redirectToHomePage();
        } else {
            this.initializeComponentDetails();
        }

    }

    initializeComponentDetails = () => {
        const { modelingMapStore, submissionStore, match } = this.props;
        
        modelingMapStore.clearMapData();

        if (match.params.submissionId) {
            this.resumedFromDraft = true;
            modelingMapStore.setShowLandingDialog(false);
            submissionStore.getDraftDetails(match.params.submissionId, true);
        }
        else {
            modelingMapStore.loadData();
        }
    }

    redirectToHomePage = () => {
        this.props.history.push('/');
    }

    componentWillUnmount() {
        //If we reinstate the persistant map state this should be removed
        const { reviewStore } = this.props;
        reviewStore.setGroupUpMode(false);
        reviewStore.setExistingMode(false);
        this.props.modelingMapStore.clearMapData();
    }

    render() {
        const { classes } = this.props;
        const { showWardInfo, selectedNuggets } = this.props.modelingMapStore;
        return (
            <React.Fragment>
                <div className={classes.mainContainer}>
                    <SubmissionCompleteDialog />
                    <RouteTransitionGuard />
                    <Header />
                    <LandingDialog />
                    <MapGuidelinesDialog />
                    <Grid container className={classes.mapAndWardContainer}>
                        <Grid item className={classes.mapContainer}>
                            <ModelingMap ref={this.mapElement} modelingStore={this.props.modelingMapStore} classes={classes} finishBtnFn={() => { this.validateAndFinishMap() }} />
                            <MapTools />
                        </Grid>
                        <Grid item className={showWardInfo ? [classes.visibleWardInfo, classes.visibleWardInfoSize].join(' ') : classes.invisibleWardInfo} >
                            {showWardInfo &&
                                (selectedNuggets.length === 0 ?
                                    <SegmentInfoPanel modelingStore={this.props.modelingMapStore} reviewStore={this.props.reviewStore} finishBtnFn={() => { this.validateAndFinishMap() }} saveBtnFn={() => { this.saveDraft() }} handleCheckMyMapClicked={() => { this.checkMyMap()}} /> :
                                    <SelectedBlockPanel />)}
                        </Grid>
                    </Grid>
                    <BrowserWarning />
                </div>
            </React.Fragment>
        );
    }

    selectSegment = (event) => {
        this.props.modelingMapStore.selectSegment(event.target.value);
        this.props.modelingMapStore.setIsMapDirty(true);
    }

    showMapWarning = (isValid, validateOnly ) => {
        let validationMessage = '';
        if(this.props.modelingMapStore.councilStructureValidationMessages.length > 0) {
            validationMessage = 'This model does not satisfy a permitted structure for this council.';
            validationMessage += '\n\n The following electoral structure(s) are permitted for this council:'
            this.props.modelingMapStore.councilStructureValidationMessages.forEach(message => {
                validationMessage += '  \n- ' + message
            });
        }

        if(this.props.modelingMapStore.validationMessages.length > 0){
            validationMessage += '\n\nBefore you make your **final** submission, check:';
            this.props.modelingMapStore.validationMessages.forEach(message => {
                validationMessage += '  \n- ' + message
            });
        }

        validationMessage += '  \n\nYou can still submit your map without resolving these issues.';
        const contentNote = '\n\n**Note: This tool does not include elector projections. The panel considers elector growth and decline when assessing medium and long-term viability of models.**';
        this.props.msgBoxStore.show({
            content: (isValid ? 'There were no issues found in your model.' : validationMessage) + contentNote,
            header: 'Check my map',
            inputOptions: { type: 'NONE' },
            hideCancel: validateOnly,
            leftAlignContent: true,
            confirmButton: validateOnly ? 'OK' : 'Continue',
            cancelButton: 'Go Back',
            size: 'sm'
        }).then(() => {
            this.validationResolver();
        }).catch(() => {
            this.clearValidation()
        });
    }

    validate(){
        const isMapValid = this.props.modelingMapStore.validateMap();
        const isStructureValid = this.props.modelingMapStore.validateCouncilStructure();
        return isMapValid && isStructureValid;
    }

    checkMyMap(){
        this.validationResolveFunction = () => {
            this.clearValidation();
        };
        if (this.validate()) {
            this.showMapWarning(true, true);
        }else{
            this.showMapWarning(false, true);
        }
    }

    saveDraft() {
        this.validationResolveFunction = () => {
            this.clearValidation();
            let { submissionStore } = this.props;
            const { modelingMapStore } = this.props;
            submissionStore.submissionMap = modelingMapStore.segments.map(segment => {
                return {
                    ...segment,
                    blocks: modelingMapStore.nuggets.filter(nugget => { return nugget.assignedSegmentId == segment.properties.id }).map(nugget => nugget.properties.blockid)
                }
            });
            submissionStore.saveSubmission(true);
        };
        if (this.validate()) {
            this.validationResolver();
        }else{
            this.showMapWarning();
        }
    }

    validationResolver() {
        if (this.validationResolveFunction) {
            this.validationResolveFunction();
        }
    }

    validateAndFinishMap() {
        this.validationResolveFunction = this.finishMap;
        if (this.validate()) {
            this.validationResolveFunction();
        } else {
            this.showMapWarning();
        }
    }

    clearValidation() {
        this.props.modelingMapStore.validationMessages.clear();
    }

    finishMap = () => {
        let { submissionStore, modelingMapStore } = this.props;
        this.clearValidation();
        submissionStore.submissionMap = modelingMapStore.segments.map(segment => {
            return {
                ...segment,
                blocks: modelingMapStore.nuggets.filter(nugget => { return nugget.assignedSegmentId == segment.properties.id }).map(nugget => nugget.properties.blockid)
            }
        });
        modelingMapStore.setIsMapDirty(false);
        submissionStore.setShowSaveDraftMessage(false);
        submissionStore.saveSubmission(true, true);
    }

    static propTypes = {
        mapElement: PropTypes.object,
        reviewId: PropTypes.string,
        classes: PropTypes.object.isRequired,
        modelingMapStore: PropTypes.object,
        rootStore: PropTypes.object,
        reviewStore: PropTypes.object,
        submissionStore: PropTypes.object,
        msgBoxStore: PropTypes.object,
        authStore: PropTypes.object,
        history: PropTypes.object,
        match: PropTypes.object,
    }
}

export default compose(
    withStyles(styles),
)(ModelingWindow);