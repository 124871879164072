import React from 'react'
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    dialogContent: {
        padding: '40px'
    },
    dialogContainer: {
        alignItems: 'center',
    },
    title: {
        fontSize: '28px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        lineHeight: '42px',
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        alignItems: 'center',
    },
    contentText: {
        padding: '10px',
        textAlign: 'center'
    },
    groundUpButton: {
        paddingRight: '20px',
        paddingLeft: '20px',
    }
});

@inject("reviewStore")
@observer
class MappingOptionsDialog extends React.Component {

    render() {
        const { classes, reviewStore } = this.props;
         const { showMappingOptionsDialog } = reviewStore;

        return (
            <Dialog open={showMappingOptionsDialog} maxWidth='md'>
                <Grid container className={classes.dialogContainer}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                        <DialogTitle>
                            <Typography color={'inherit'} component='div' variant={'h4'} className={classes.title}>How would you like to start?</Typography>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        fullWidth
                                        className={classes.groundUpButton}
                                        onClick={this.handleGroupUpModeClick}
                                    >
                                        Build from the ground up
                                    </Button>
                                    <Typography className={classes.contentText} variant="subtitle2">
                                        Start with a blank canvas if you wish to suggest major changes to the council structure.
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        fullWidth
                                        onClick={this.handleExistingModeClick}
                                    >
                                        Use existing structure
                                    </Button>
                                    <Typography className={classes.contentText} variant="subtitle2">
                                        Start with the current structure if you want to use it as the basis for your model.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Dialog>
        )
    }

    handleGroupUpModeClick = () => {
        const {reviewStore } = this.props;
        reviewStore.showMappingOptionsDialog = false;
        reviewStore.setGroupUpMode(true);
        reviewStore.setCouncillorsSelectionDialog(true);
    }

    handleExistingModeClick = () => {
        const { reviewStore } = this.props;

        reviewStore.setExistingMode(true);
        reviewStore.startMapBasedSubmission(reviewStore.currentDraftSubmissionId);
    }
}

MappingOptionsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    reviewStore: PropTypes.object,
    modelingMapStore: PropTypes.object
};

export default withStyles(styles)(MappingOptionsDialog);
