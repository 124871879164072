import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Grid, Typography, Link } from '@material-ui/core';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    backgroundContainer: {
        background: theme.palette.secondary.background,
        height: 'calc(100vh - 65px)',
        padding: '40px 0'
    },
    errorMessageContainer: {
        borderRadius: '4px',
        color: theme.palette.secondary.charcoal,
        border: '4px solid ' + theme.palette.secondary.main,
        padding: '36px 45px',
        '& h1': {
            color: theme.palette.secondary.main,
            paddingBottom: '19px',
            fontSize: '28px',
            lineHeight: '42px'
        },
        '& h2': {
            fontSize: '14px',
            lineHeight: '21px',
            letterSpacing: '0.4px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '20px 35px',
            marginBottom: '30px'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '20px'
        },
    },
    linkStyle: {
        fontSize: '14px',
    }
})

@observer
class ErrorMessage extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <Grid container className={classes.backgroundContainer} justifyContent="center">
                <Grid item xs={10} sm={10} md={8}>
                    <div className={classes.errorMessageContainer}>
                        <Typography variant='h1'>Inactive review</Typography>
                        <Typography variant='h2'>
                            This council is not currently having a review. <Link className={classes.linkStyle} underline='always' target='_blank' href='https://www.vec.vic.gov.au/'>Return to the VEC home page</Link>.
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

ErrorMessage.propTypes = {
    classes: PropTypes.object,
    reviewStore: PropTypes.object,
};

export default compose(
    withStyles(styles)
)(ErrorMessage);
