import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    lblMessage: {
        fontWeight: 600,
        display: 'inline',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    },
    msgHelpText: {
        display: 'flex',
        justifyContent: 'flex-end',
        float: 'right',
    },
    gridMessage: {
        marginTop: '5px',
        width: '100%',
        overflowX: "hidden",
        overflowY: "auto",
        '&& .MuiOutlinedInput-root': {
            borderRadius: 0,
            '& fieldset legend span': {
                visibility: 'hidden',
                width: 0
            }
        },
    }
});

const submissionTextMaxLength = 8000;

@inject('submissionStore')
@observer
class SubmissionText extends React.Component {
    constructor(props) {
        super(props);
    }

    onMessageChange = event => {
        const { submissionStore } = this.props;

        submissionStore.setIsWrittenSubmissionFormDirty(true);
        submissionStore.submissionText = event.target.value;
    };

    render() {
        const { classes, submissionStore } = this.props;
        let initialMessageValue = "";
        if (submissionStore.submissionText) {
            initialMessageValue = submissionStore.submissionText;
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" color="secondary" className={classes.lblMessage}>
                        Message
                    </Typography>
                    {
                        (submissionTextMaxLength - submissionStore.submissionPlainTextLength <= 0) &&
                        <Typography variant="body1" className={classes.msgHelpText}>
                            Maximum character limit reached.
                        </Typography>
                    }
                </Grid>
                
                <Grid item xs={12} className={classes.gridMessage}>
                    <TextField
                        id='message'
                        name='message'
                        placeholder='Enter your message here'
                        multiline
                        fullWidth
                        minRows={15}
                        value={initialMessageValue}
                        onChange={this.onMessageChange}
                        variant='outlined'
                        inputProps={{
                            'aria-label': 'Message',
                            maxLength: 8000
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}

SubmissionText.propTypes = {
    classes: PropTypes.object.isRequired,
    submissionStore: PropTypes.object
}

export default withStyles(styles)(SubmissionText);
