import { observable, computed, action, runInAction } from 'mobx';
import moment from 'moment';
import { Enums } from '../enums';
import councilService from '../services/council.service';

export default class ReviewStore {

    constructor(sessionStore) {
        this.sessionStore = sessionStore;
        this.routerStore = sessionStore.routerStore;
        this.authStore = sessionStore.authStore;
        this.msgBoxStore = sessionStore.msgBoxStore;
    }

    @observable activeRedivision = null;
    @observable requestError = null;
    @observable councilReviewStatus = false;
    @observable reviewCouncilName = "";
    @observable reviewCouncilId = "";
    @observable reviewDetails = null;
    @observable showMappingOptionsDialog = false;
    @observable isGroundUpModeSelected = false;
    @observable isExistingModeSelected = false;
    @observable showCouncillorsSelectorDialog = false;
    @observable currentDraftSubmissionId = null;

    @computed get displayMakeSubmissions() {
        const submissionStore = this.sessionStore.submissionStore;
        const authStore = this.sessionStore.authStore;
        const submissions = submissionStore.currentSubmissions;
        const hasRetrievedSubmissions = submissionStore.hasRetrievedSubmissions;
        let hasDraftStageOne = false;
        let hasDraftStageTwo = false;
        let hasDraftStageThree = false;

        if (authStore.isUserAuthenticated) {

            if (hasRetrievedSubmissions) {
                hasDraftStageOne = submissionStore.hasDraftSubmission(submissions, Enums.SubmissionStage.Preliminary, this.reviewDetails.reviewType);
                hasDraftStageTwo = submissionStore.hasDraftSubmission(submissions, Enums.SubmissionStage.Response, this.reviewDetails.reviewType);
                hasDraftStageThree = submissionStore.hasDraftSubmission(submissions, Enums.SubmissionStage.Addendum, this.reviewDetails.reviewType);
                return (this.isPreliminarySubmissionsActive || this.isResponseSubmissionsActive || this.isAddendumSubmissionsActive) && !((hasDraftStageOne && this.isPreliminarySubmissionsActive) || (hasDraftStageTwo && this.isResponseSubmissionsActive) || (hasDraftStageThree && this.isAddendumSubmissionsActive));
            }
            else {
                return false;
            }
        }
        else {
            return this.isPreliminarySubmissionsActive || this.isResponseSubmissionsActive || this.isAddendumSubmissionsActive;
        }
    }

    @computed get displaySignInFooterNote() {
        return !this.sessionStore.authStore.isUserAuthenticated;
    }

    @computed get isInitialSubmissionOpen() {
        return this.activeRedivision && this.activeRedivision.isInitialSubmissionOpen;
    }

    @computed get isStageOneActive() {
        return this.activeRedivision && this.activeRedivision.isInitialSubmissionOpen && !this.activeRedivision.isInitialSubmissionClose;
    }

    @computed get isStageOneClosed() {
        return this.activeRedivision && this.activeRedivision.isInitialSubmissionOpen && this.activeRedivision.isInitialSubmissionClose;
       
    }

    @computed get isStageTwoActive() {
        return this.activeRedivision && this.activeRedivision.isProposedBoundariesPublished && !this.activeRedivision.isSuggestionsAndObjectsionsClosed;
    }

    @computed get isStageTwoClosed() {
        return this.activeRedivision && this.activeRedivision.isProposedBoundariesPublished && this.activeRedivision.isSuggestionsAndObjectsionsClosed;
    }

    @computed get isProposedBoundariesPublished() {
        return this.reviewDetails && this.reviewDetails.isProposedBoundariesPublished;
    }

    @computed get isPreliminarySubmissionsOpen() {
        return this.reviewDetails && this.reviewDetails.isPreliminarySubmissionsOpen;
    }

    @computed get isPreliminarySubmissionsActive() {
        return this.reviewDetails && this.reviewDetails.isPreliminarySubmissionsOpen && !this.reviewDetails.isPreliminarySubmissionsClose;
    }
    
    @computed get isPreliminarySubmissionsClosed() {
        return this.reviewDetails && this.reviewDetails.isPreliminarySubmissionsOpen && this.reviewDetails.isPreliminarySubmissionsClose;
    }

    @computed get isPreliminarySubmissionsMappingOptionsEnabled() {
        return this.reviewDetails.mappingOptions && this.reviewDetails.isPreliminarySubmissionsOpen && !this.reviewDetails.isPreliminarySubmissionsClose && this.reviewDetails.mappingOptions.preliminarySubmissionsEnabled;
    }

    @computed get isResponseSubmissionsOpen() {
        return this.reviewDetails && this.reviewDetails.isPreliminaryReportPublished;
    }

    @computed get isResponseSubmissionsActive() {
        return this.reviewDetails && this.reviewDetails.isPreliminaryReportPublished && !this.reviewDetails.isResponseSubmissionsClose;
    }
    
    @computed get isResponseSubmissionsClosed() {
        return this.reviewDetails && this.reviewDetails.isPreliminaryReportPublished && this.reviewDetails.isResponseSubmissionsClose;
    }

    @computed get isResponseSubmissionsMappingOptionsEnabled() {
        return this.reviewDetails.mappingOptions && this.reviewDetails.isPreliminaryReportPublished && !this.reviewDetails.isResponseSubmissionsClose && this.reviewDetails.mappingOptions.responseSubmissionsEnabled;
    }

    @computed get isAddendumSubmissionsOpen() {
        return this.reviewDetails && this.reviewDetails.isAddendumSubmissionsOpen;
    }

    @computed get isAddendumSubmissionsActive() {
        return this.reviewDetails && this.reviewDetails.isAddendumSubmissionsOpen && !this.reviewDetails.isAddendumSubmissionsClose;
    }
    
    @computed get isAddendumSubmissionsClosed() {
        return this.reviewDetails && this.reviewDetails.isAddendumSubmissionsOpen && this.reviewDetails.isAddendumSubmissionsClose;
    }

    @computed get isAddendumSubmissionsMappingOptionsEnabled() {
        return this.reviewDetails.mappingOptions && this.reviewDetails.isAddendumSubmissionsOpen && !this.reviewDetails.isAddendumSubmissionsClose && this.reviewDetails.mappingOptions.addendumEnabled;
    }

    @computed get displayMappingTile() {
        return this.isAddendumSubmissionsMappingOptionsEnabled || this.isPreliminarySubmissionsMappingOptionsEnabled || this.isResponseSubmissionsMappingOptionsEnabled;
    }

    @computed get isSingleCouncillorPermitted(){
        return ((this.reviewDetails.reviewType === Enums.ReviewType.Full || this.reviewDetails.reviewType === Enums.ReviewType.ResponseStage)
                && this.reviewDetails.councilStructure && this.reviewDetails.councilStructure.isSingleCouncillor);
    }
    @computed get isMultiCouncillorPermitted(){
        return ((this.reviewDetails.reviewType === Enums.ReviewType.Full || this.reviewDetails.reviewType === Enums.ReviewType.ResponseStage)
                && this.reviewDetails.councilStructure && this.reviewDetails.councilStructure.isMultiCouncillor);
    }
    @computed get isUnsubdividedWardPermitted(){
        return ((this.reviewDetails.reviewType === Enums.ReviewType.Full || this.reviewDetails.reviewType === Enums.ReviewType.ResponseStage)
                && this.reviewDetails.councilStructure && this.reviewDetails.councilStructure.isUnsubdivided);
    }

    @action getMilestoneDate = (milestoneType) => {
        let result = null;

        if (this.activeRedivision && this.activeRedivision.milestones && this.activeRedivision.milestones.length > 0) {
            const milestone = this.activeRedivision.milestones.find(m => m.dateType === milestoneType);

            if (milestone) {
                result = moment(milestone.date);
            }
        }

        return result;
    }

    @action getReviewStagesDates = (milestoneType) => {
        let result = null;

        if (this.reviewDetails && this.reviewDetails.milestones && this.reviewDetails.milestones.length > 0) {
            const milestone = this.reviewDetails.milestones.find(m => m.dateType === milestoneType);

            if (milestone) {
                result = moment(milestone.date);
            }
        }

        return result;
    }

    @action startTextBasedSubmission = () => {
        const { authStore, routerStore, submissionStore } = this.sessionStore;
        if (authStore.isUserAuthenticated) {
            routerStore.history.push('/writtensubmission?councilId=' + this.reviewDetails.councilId);
        } else {
            authStore.authenticateUser().then(() => {
                submissionStore.scrollToSubmissions = true;
            });
        }
    }

    @action startMapBasedSubmission = (submissionId) => {
        this.showMappingOptionsDialog = false;
        this.authStore.authenticateUser().then(() => {
            let mapUrl = '/mapping/' + (submissionId? submissionId : '') + '?councilId=' + this.reviewCouncilId;
            this.msgBoxStore.show({
                header: 'Map processing',
                content: 'Please note that loading, saving or submitting a map may take up to 60 seconds depending on your computer\'s capacity.',
                size: 'sm',
                inputOptions: { type: 'NONE' },
                hideCancel: true,
                confirmButton: 'Continue'
            }).then(() => {
                this.routerStore.history.push(mapUrl);
            }).catch((err) => {
                if (err) {
                    console.log(err);
                }
            });            
        });
    }

    @action displaySignInPopup = () => {
        if (!this.authStore.isUserAuthenticated) {
            this.authStore.authenticateUser();
        }
    }

    @computed get submissionStage() {
        if (this.isPreliminarySubmissionsActive) {
            return Enums.SubmissionStage.Preliminary;
        }
        else if (this.isResponseSubmissionsActive) {
            return Enums.SubmissionStage.Response;
        } else if (this.isAddendumSubmissionsActive) {
            return Enums.SubmissionStage.Addendum;
        } else {
            return Enums.SubmissionStage.None;
        }
    }

    @action
    getCouncilReviewDetails(councilId) {
        const { rootStore } = this.sessionStore;
        rootStore.setBusy(true);
        councilService.getCouncilDetails(councilId).then((response) => {
            runInAction(() => {
                this.reviewDetails = response.review;
                this.councilReviewStatus = response.review.isReviewEnabled;
                this.reviewCouncilName = response.displayName;
                this.reviewCouncilId = response.councilId;
            });
            rootStore.setBusy(false);
            }
        ).catch((error) => {
            this.getCouncilDetailsError = error;
            rootStore.setBusy(false);
        });
    }

    @action setGroupUpMode(show) {
        this.isGroundUpModeSelected = show;
    }

    @action setExistingMode(show) {
        const { modelingMapStore } = this.sessionStore;
        modelingMapStore.councillorsSetFromGroundUp = false;
        this.isExistingModeSelected = show;
    }

    @action setMappingOptionsDialog = (submissionId) => {
        const { authStore, submissionStore } = this.sessionStore;
        submissionId ? this.currentDraftSubmissionId = submissionId : null;
        if (authStore.isUserAuthenticated) {
            this.manageMappingOptionsDialog(submissionId);
        } else {
            authStore.authenticateUser().then(() => {
                submissionStore.scrollToSubmissions = true;
            });
        }
    }

    @action setCouncillorsSelectionDialog = (show) => {
        this.showCouncillorsSelectorDialog = show;
        
        if(!show)
        {
            this.startMapBasedSubmission(this.currentDraftSubmissionId);
        }
    }

    @action manageMappingOptionsDialog = (submissionId) => {
        submissionId ? this.currentDraftSubmissionId = submissionId : null;
        if(this.reviewDetails && this.reviewDetails.mappingOptions.preliminarySubmissionsGroundUp && this.reviewDetails.mappingOptions.preliminarySubmissionsExistingStructure && this.isPreliminarySubmissionsActive) {
            this.showMappingOptionsDialog = true;
        }else  if(this.reviewDetails && this.reviewDetails.mappingOptions.responseSubmissionsGroundUp && this.reviewDetails.mappingOptions.responseSubmissionsExistingStructure && this.isResponseSubmissionsActive) {
            this.showMappingOptionsDialog = true;
        }else if(this.reviewDetails && this.reviewDetails.mappingOptions.addendumGroundUp && this.reviewDetails.mappingOptions.addendumExistingStructure && this.isAddendumSubmissionsActive) {
            this.showMappingOptionsDialog = true;
        }else if(this.reviewDetails && this.reviewDetails.mappingOptions.preliminarySubmissionsGroundUp && this.isPreliminarySubmissionsActive) {
            this.setGroupUpMode(true);
            this.setCouncillorsSelectionDialog(true);
        }else if(this.reviewDetails && this.reviewDetails.mappingOptions.preliminarySubmissionsExistingStructure && this.isPreliminarySubmissionsActive) {
            this.setExistingMode(true);
            this.startMapBasedSubmission(submissionId);
        }else if(this.reviewDetails && this.reviewDetails.mappingOptions.responseSubmissionsGroundUp && this.isResponseSubmissionsActive) {
            this.setGroupUpMode(true);
            this.setCouncillorsSelectionDialog(true);
        }else if(this.reviewDetails && this.reviewDetails.mappingOptions.responseSubmissionsExistingStructure && this.isResponseSubmissionsActive) {
            this.setExistingMode(true);
            this.startMapBasedSubmission(submissionId);
        }else if(this.reviewDetails && this.reviewDetails.mappingOptions.addendumGroundUp && this.isAddendumSubmissionsActive) {
            this.setGroupUpMode(true);
            this.setCouncillorsSelectionDialog(true);
        }else if(this.reviewDetails && this.reviewDetails.mappingOptions.addendumExistingStructure && this.isAddendumSubmissionsActive) {
            this.setExistingMode(true);
            this.startMapBasedSubmission(submissionId);
        }
    } 
}