import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SwipeableViews from 'react-swipeable-views';
import { Grid, IconButton } from '@material-ui/core';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import RedivisionStageDetails from './redivision-stage-details';
import { Enums } from '../../../enums';
import ActiveStage1Logo from '../../../../static/images/stage1_active.svg';
import ActiveStage2Logo from '../../../../static/images/stage2_active.svg';
import ActiveStage3Logo from '../../../../static/images/stage3_active.svg';
import ActiveStage4Logo from '../../../../static/images/stage_4_active.svg';
import InActiveStage1Logo from '../../../../static/images/stage1_inactive.svg';
import InActiveStage2Logo from '../../../../static/images/stage2_inactive.svg';
import InActiveStage3Logo from '../../../../static/images/stage3_inactive.svg';
import InActiveStage4Logo from '../../../../static/images/Stage_4_Inactive.svg';

const styles = theme => ({
    container: {
        margin: '0 15px',
        display: 'block'
    },
    pagination: {
        justifyContent: 'center'
    },
    stageBtn: {
        height: 18,
        width: 18,
        cursor: 'pointer',
        border: 0,
        background: 'none',
        padding: 0
    },
    paginationDot: {
        backgroundColor: theme.palette.secondary.light,
        height: 12,
        width: 12,
        borderRadius: 6,
        margin: 3
    },
    currentPaginationDot: {
        backgroundColor: theme.palette.secondary.main,
        height: 12,
        width: 12,
        borderRadius: 6,
        margin: 3
    }
})

@inject('reviewStore')
@observer
class RedivisionStagesSwipe extends Component {
    state = {
        index: 0
    };

    handleChangeIndex = index => {
        this.setState({ index });
    };

    componentDidMount() {

        var i = 0;

        const { getReviewStagesDates} = this.props.reviewStore;
        const addendumSubmissionsCloseTime = getReviewStagesDates(Enums.MilestoneType.AddendumClose);

        if (this.props.reviewStore.reviewDetails.reviewType !== Enums.ReviewType.Full)
        {
            if (this.props.reviewStore.isResponseSubmissionsClosed)
            {
                i++;
            }
            if (addendumSubmissionsCloseTime != null && this.props.reviewStore.isAddendumSubmissionsClosed)
            {
                i++;
            }
        }
        else
        {
            if (this.props.reviewStore.isPreliminarySubmissionsClosed)
            {
                i++;
            }
            if (this.props.reviewStore.isResponseSubmissionsClosed)
            {
                i++;
            }
            if (addendumSubmissionsCloseTime != null && this.props.reviewStore.isAddendumSubmissionsClosed)
            {
                i++;
            }
        }

        this.setState({index: i});
    }

    render() {
        let { index } = this.state;
        const { classes, reviewStore } = this.props;
        const { getReviewStagesDates, reviewDetails } = reviewStore;
        const stagePaginationButtons = [];
        const addendumSubmissionsCloseTime = getReviewStagesDates(Enums.MilestoneType.AddendumClose);
        const paginationCount = (addendumSubmissionsCloseTime != null) ? ((reviewDetails.reviewType === Enums.ReviewType.Full) ? 4: 3) : ((reviewDetails.reviewType === Enums.ReviewType.Full) ? 3: 2);

        for (let i = 0; i < paginationCount; i += 1) {
            stagePaginationButtons.push(
                <IconButton key={i} index={i} className={classes.stageBtn} onClick={() => this.handleChangeIndex(i)}  title={'Page ' + (i+1)}>
                    <Grid className={i === index ? classes.currentPaginationDot : classes.paginationDot} />
                </IconButton>
            );
        }

        return (
            <>
                {reviewDetails &&
                    <>
                    {
                      (addendumSubmissionsCloseTime != null) ? 
                       (reviewDetails.reviewType !== Enums.ReviewType.Full) ? 
                        <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
                        <Grid item xs={12} className={classes.container}>
                            {this.getResponseStage()}
                        </Grid>
                        <Grid item xs={12} className={classes.container}>
                            {this.getAddendumStage()}
                        </Grid>
                        <Grid item xs={12} className={classes.container}>
                            {this.getFinalStage()}
                        </Grid>
                        </SwipeableViews> :
                        <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
                            <Grid item xs={12} className={classes.container}>
                                {this.getPreliminaryStage()}
                            </Grid>
                            <Grid item xs={12} className={classes.container}>
                                {this.getResponseStage()}
                            </Grid>
                            <Grid item xs={12} className={classes.container}>
                                {this.getAddendumStage()}
                            </Grid>
                            <Grid item xs={12} className={classes.container}>
                                {this.getFinalStage()}
                            </Grid>
                        </SwipeableViews>
                    : // Addendum stage not active
                    (reviewDetails.reviewType !== Enums.ReviewType.Full) ? 
                        <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
                        <Grid item xs={12} className={classes.container}>
                            {this.getResponseStage()}
                        </Grid>
                        <Grid item xs={12} className={classes.container}>
                            {this.getFinalStage()}
                        </Grid>
                        </SwipeableViews> :
                        <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
                            <Grid item xs={12} className={classes.container}>
                                {this.getPreliminaryStage()}
                            </Grid>
                            <Grid item xs={12} className={classes.container}>
                                {this.getResponseStage()}
                            </Grid>
                            <Grid item xs={12} className={classes.container}>
                                {this.getFinalStage()}
                            </Grid>
                        </SwipeableViews>}
                    <Grid container className={classes.pagination}>{stagePaginationButtons}</Grid>
                    </>}
            </>
        );
    }

    getPreliminaryStage = () => {
        const { reviewStore } = this.props;
        const { getReviewStagesDates, isPreliminarySubmissionsActive, isPreliminarySubmissionsClosed} = reviewStore;

        const preliminarySubmissionsOpenTime = getReviewStagesDates(Enums.MilestoneType.PreliminarySubmissionOpen);
        const preliminarySubmissionsCloseTime = getReviewStagesDates(Enums.MilestoneType.PreliminarySubmissionsClose);
                
        return (<RedivisionStageDetails
            title='Preliminary submissions'
            subTitle={isPreliminarySubmissionsActive ?
                <>Open now <br /> Close {preliminarySubmissionsCloseTime.format('ha')} on {preliminarySubmissionsCloseTime.format('D MMMM YYYY')}</> :
                (isPreliminarySubmissionsClosed ? <>Closed</> :
                    <>Open {preliminarySubmissionsOpenTime.format('D MMMM YYYY')} <br /> Close {preliminarySubmissionsCloseTime.format('ha')} on {preliminarySubmissionsCloseTime.format('D MMMM YYYY')}</>)}
            imageLogo={isPreliminarySubmissionsActive ? ActiveStage1Logo : InActiveStage1Logo}
            content={<>At the start of the review, the Electoral Representation Advisory Panel invites submissions about the local council&apos;s electoral structure.</>} councilName={this.props.reviewStore.reviewCouncilName} />);
    }

    getResponseStage = () => {
        const { reviewStore } = this.props;
        const { reviewDetails, getReviewStagesDates, isResponseSubmissionsActive, isResponseSubmissionsClosed } = reviewStore;

        const responseSubmissionsOpenTime = getReviewStagesDates(Enums.MilestoneType.PreliminaryReportPublished);
        const responseSubmissionsCloseTime = getReviewStagesDates(Enums.MilestoneType.ResponseSubmissionsClose);
                
        return (<RedivisionStageDetails
                title='Response submissions'
                subTitle={isResponseSubmissionsActive ?
                    <>Open now <br /> Close {responseSubmissionsCloseTime.format('ha')} on {responseSubmissionsCloseTime.format('D MMMM YYYY')}</> :
                    (isResponseSubmissionsClosed ? <>Closed</> :
                        <>Open {responseSubmissionsOpenTime.format('D MMMM YYYY')} <br /> Close {responseSubmissionsCloseTime.format('ha')} on {responseSubmissionsCloseTime.format('D MMMM YYYY')}</>)}
                imageLogo={(reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && isResponseSubmissionsActive ? ActiveStage1Logo : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) ? InActiveStage1Logo :  reviewDetails.reviewType === Enums.ReviewType.Full && isResponseSubmissionsActive ? ActiveStage2Logo : InActiveStage2Logo}
                content={ reviewDetails.reviewType === Enums.ReviewType.Full || reviewDetails.reviewType === Enums.ReviewType.ResponseStage ? 
                            <>The Electoral Representation Advisory Panel publishes a preliminary report describing one or more options for the local council&apos;s electoral structure. You can submit a response to the panel&apos;s proposed options.</>
                            : reviewDetails.reviewType === Enums.ReviewType.WardBoundary ? 
                                <>The review panel publishes a preliminary report describing one or more options for adjusting the ward boundaries of the local council. You can submit a response to the panel&apos;s proposed options.</> : ""} councilName={this.props.reviewStore.reviewCouncilName} />);
    }

    getAddendumStage = () => {
        const { reviewStore } = this.props;
        const { reviewDetails, getReviewStagesDates, isAddendumSubmissionsActive, isAddendumSubmissionsClosed} = reviewStore;

        const addendumSubmissionsOpenTime = getReviewStagesDates(Enums.MilestoneType.AddendumOpen);
        const addendumSubmissionsCloseTime = getReviewStagesDates(Enums.MilestoneType.AddendumClose);
   
        return (<RedivisionStageDetails
                title='Addendum submissions'
                subTitle={isAddendumSubmissionsActive ?
                    <>Open now <br /> Close {addendumSubmissionsCloseTime.format('ha')} on {addendumSubmissionsCloseTime.format('D MMMM YYYY')}</> :
                    (isAddendumSubmissionsClosed ? <>Closed</> :
                        <>Open {addendumSubmissionsOpenTime.format('D MMMM YYYY')} <br /> Close {addendumSubmissionsCloseTime.format('ha')} on {addendumSubmissionsCloseTime.format('D MMMM YYYY')}</>)}
                imageLogo={reviewDetails.reviewType === Enums.ReviewType.Full && isAddendumSubmissionsActive ? ActiveStage3Logo : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && isAddendumSubmissionsActive ? ActiveStage2Logo : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) ? InActiveStage2Logo : InActiveStage3Logo}
                content={reviewDetails.reviewType === Enums.ReviewType.Full || reviewDetails.reviewType === Enums.ReviewType.ResponseStage ? 
                    <>The Electoral Representation Advisory Panel publishes an addendum report describing one or more options for the local council&apos;s electoral structure. You can submit a response to the panel&apos;s proposed options.</>
                    : reviewDetails.reviewType === Enums.ReviewType.WardBoundary ? 
                        <>The review panel publishes an addendum report describing one or more options for adjusting the ward boundaries of the local council. You can submit a response to the panel&apos;s proposed options.</> : ""} councilName={this.props.reviewStore.reviewCouncilName} />);
    }

    getFinalStage = () => {
        const { reviewStore } = this.props;
        const { reviewDetails, getReviewStagesDates, isResponseSubmissionsClosed, isAddendumSubmissionsClosed} = reviewStore;

        const finalReportPublishedTime = getReviewStagesDates(Enums.MilestoneType.FinalReportPublished);
        const addendumSubmissionsOpenTime = getReviewStagesDates(Enums.MilestoneType.AddendumOpen);
   
        return (<RedivisionStageDetails
                title='Final report'
                subTitle={<>{(reviewDetails.reviewType === Enums.ReviewType.Full || reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) ? "" : "Available "}{finalReportPublishedTime.format('D MMMM YYYY')}</>}
                imageLogo={reviewDetails.reviewType === Enums.ReviewType.Full && addendumSubmissionsOpenTime && isAddendumSubmissionsClosed ? ActiveStage4Logo 
                    : reviewDetails.reviewType === Enums.ReviewType.Full && addendumSubmissionsOpenTime && !isAddendumSubmissionsClosed ? InActiveStage4Logo
                    : reviewDetails.reviewType === Enums.ReviewType.Full && !addendumSubmissionsOpenTime && isResponseSubmissionsClosed ? ActiveStage3Logo
                    : reviewDetails.reviewType === Enums.ReviewType.Full && !addendumSubmissionsOpenTime && !isResponseSubmissionsClosed ? InActiveStage3Logo
                    : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && addendumSubmissionsOpenTime && isAddendumSubmissionsClosed ? ActiveStage3Logo
                    : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && addendumSubmissionsOpenTime && !isAddendumSubmissionsClosed ? InActiveStage3Logo
                    : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && !addendumSubmissionsOpenTime && isResponseSubmissionsClosed ? ActiveStage2Logo
                    : (reviewDetails.reviewType === Enums.ReviewType.ResponseStage || reviewDetails.reviewType === Enums.ReviewType.WardBoundary) && !addendumSubmissionsOpenTime && !isResponseSubmissionsClosed ? InActiveStage2Logo : ""}
                    content={reviewDetails.reviewType === Enums.ReviewType.Full || reviewDetails.reviewType === Enums.ReviewType.ResponseStage ? 
                        <>The Electoral Representation Advisory Panel provides a report to the Minister for Local Government with the final recommendation for the local council&apos;s electoral structure. The Minister must consider this recommendation when changing the electoral structure of the local council.</>
                    : reviewDetails.reviewType === Enums.ReviewType.WardBoundary ? 
                        <>The review panel provides a report to the Minister for Local Government with the final recommendation for adjusting the ward boundaries of the local council. The Minister must consider this recommendation when adjusting the ward boundaries of the local council.</> : ""} councilName={this.props.reviewStore.reviewCouncilName} />);
    }
}

RedivisionStagesSwipe.propTypes = {
    classes: PropTypes.object,
    reviewStore: PropTypes.object,
};

export default compose(
    withStyles(styles)
)(RedivisionStagesSwipe);
