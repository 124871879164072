import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Header from '../../header';
import StartSubmission from './start-submission';
import RedivisionStages from './redivision-stages';
import BrowserWarning from '../../browser-warning';
import { inject, observer } from 'mobx-react';
import ErrorMessage from './error-message';


@inject('reviewStore', 'rootStore')

@observer
class LandingPage extends React.Component {
    componentDidMount() {
        this.checkCouncilReviewStatus(this.props.match.params.councilId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {match} = nextProps;
        const {match: prevMatch} = this.props;
        if(match.params.councilId !== prevMatch.params.councilId){
            this.checkCouncilReviewStatus(match.params.councilId);
        }
    }

    async checkCouncilReviewStatus(councilId) {
        await this.props.reviewStore.getCouncilReviewDetails(councilId);
    }

    render() {
        const { rootStore, reviewStore } = this.props;

        return (
            <Grid container>
                <Header/>
                {reviewStore.councilReviewStatus &&
                    <React.Fragment>
                        <RedivisionStages/>
                        <StartSubmission/>
                        <BrowserWarning/>
                    </React.Fragment>
                }
                {!rootStore.isBusy && !reviewStore.councilReviewStatus && <ErrorMessage/>}
            </Grid>
        );
    }

    static propTypes = {
        match: PropTypes.object
    };
}

LandingPage.propTypes = {
    reviewStore: PropTypes.object,
    rootStore: PropTypes.object,
};

export default LandingPage;