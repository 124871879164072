import { withStyles, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const primaryOutlinedBtnTheme = createTheme();

const StyledPrimaryOutlinedButton = withStyles({
    root: {
        width: '185px',
        marginBottom: '15px',
        border: '2px solid',
        borderColor: '#D93C29',
        boxSizing: 'border-box',
        borderRadius: '3px',
        textTransform: 'none',
        height: '50px',
        minWidth: '180px',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        flex: 'none',
        order: 0,
        alignSelf: 'center',

        [primaryOutlinedBtnTheme.breakpoints.down('sm')]: {
            minWidth: '140px',
            width: '140px',
            marginBottom: '15px'
        },
        [primaryOutlinedBtnTheme.breakpoints.down('xs')]: {
            minWidth: 'unset',
            width: '100% !important',
            marginBottom: '15px'
        },
    },
    label: {
      textTransform: 'none',
    },
  })(Button);

  export default StyledPrimaryOutlinedButton;