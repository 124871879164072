import { observable, action, runInAction } from "mobx";

import configurationServcie from '../services/configuration.service';

export default class ConfigurationStore {
    constructor(sessionStore) {
        this.sessionStore = sessionStore;
    }

    @observable editContentDialogOpen = false;
    @observable editableContent = null;
    @observable getEditableContentError = null;
    @observable updateContentTextResult = null;
    @observable updateContentTextError = null;

    @action
    setEditContentDialogOpen(open) {
        this.editContentDialogOpen = open;
    }

    @action
    getEditableContent() {
        configurationServcie.getEditableContent().then((response) => {
            runInAction(() => {
                this.editableContent = response;
            });
        }).catch((error) => {
            this.getEditableContentError = error;
        });
    }

    @action
    findEditableContentTextByName(name) {
        const editableContent = this.findEditableContentByName(name);

        let result = '';
        if (editableContent) {
            result = editableContent.contentText;
        }

        return result;
    }

    @action
    findEditableContentByName(name) {
        const editableContent = this.editableContent || [];
        const searchResult = editableContent.filter(content => content.contentName === name);

        let result = null;
        if (searchResult.length > 0) {
            result = searchResult[0];
        }

        return result;
    }

    @action
    updateContentText(editableContent) {
        configurationServcie.updateContentText(editableContent).then((response) => {
            runInAction(() => {
                this.updateContentTextResult = response;
            });

            const editableContent = this.findEditableContentByName(this.updateContentTextResult.contentName);
            if (editableContent) {
                editableContent.contentText = this.updateContentTextResult.contentText;
            }
        }).catch((error) => {
            this.updateContentTextError = error;
        });
    }
}
