import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import SubmissionCompleteDialog from '../submission/submission-complete-dialog'
import MoreInfoTooltip from './more-info-tooltip';
import DeleteIcon from '../../../../static/images/delete-icon.svg';
import StyledPrimaryContainedButton from '../../styled-primary-contained-button';
import StyledPrimaryOutlinedButton from '../../styled-primary-outlined-button';
import { Messages } from '../../../messages'

const styles = theme => ({
    root: {
        padding: '20px',
        backgroundColor: 'white'
    },
    stageTextContainer: {
        paddingBottom: '20px'
    },
    stageIndicator: {
        paddingRight: '10px',
        marginBottom: '-5px',
        display: 'inline-block'
    },
    stageText: {
        color: '#90b669',
        display: 'inline-block'
    },
    titleContainer: {
        paddingBottom: '20px'
    },
    draftContainer: {
        backgroundColor: theme.palette.gray.gray400,
        borderRadius: '4px',
        [theme.breakpoints.up('md')]: {
            height: '70px'
        }
    },
    linkText: {
        textTransform: 'none'
    },
    underline: {
        textDecoration: 'underline'
    },
    draftDetailsText: {
        display: 'inline-block'
    },
    draftDetailsButton: {
        textTransform: 'none',
        width: '100%',
        height: '40px',
        '&:hover': {
            background: theme.palette.secondary.dark,
        }
    },
    deleteDraftButton: {
        textTransform: 'none',
        width: '100%',
        height: '40px',
        borderColor: theme.palette.secondary.dark
    },
    submitButton: {
        height: '40px',
        '&:hover': {
            background: theme.palette.secondary.dark
        },
        [theme.breakpoints.up('sm')]: {
            textTransform: 'none',
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: '70%',
            minWidth: '50px',
            maxWidth: '180px'
        }

    },
    firstColumn: {
        paddingLeft: '20px'
    },
    lastEditedDate: {
        color: '#605F60',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px'
    },
    draftInfoContainerSmall: {
        padding: '10px'
    },
    gutterBottom: {
        marginBottom: '10px'
    },
    deleteConfirmButton: {
        textTransform: 'none'
    },
    moreInfoText: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '27px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    },
    tableHeadingText: {
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.2px'
    },
    subText:{
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        letterSpacing: '0.2px',
        color: theme.palette.secondary.charcoal
    }
});

@inject('submissionStore', 'msgBoxStore', 'reviewStore')
@observer
class DraftSubmissions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitTooltipOpen: false,
            deleteDraftDialogOpen: false,
            submissionIdToDelete: null
        };
    }

    render() {
        const { classes, stage, reviewType, submissionStore, reviewStore } = this.props;
        const { currentSubmissions } = submissionStore;

        const draftSubmission = submissionStore.getDraftSubmission(currentSubmissions, stage, reviewType);

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12} className={classes.titleContainer}>
                        <Typography variant="h3" className={classes.moreInfoText}>
                            My draft submissions
                        </Typography>
                    </Grid>
                    {
                        draftSubmission &&
                        <Grid container>
                            <Hidden smDown>
                                <Grid container item xs={12}>
                                    <Grid container item xs={3} className={classes.firstColumn}>
                                        <Typography variant="h4" className={classes.tableHeadingText}>
                                            Last edited
                                        </Typography>
                                    </Grid>
                                    <Grid container justifyContent="center" item xs={3} >
                                        <Typography variant="h4" className={classes.tableHeadingText}>
                                            Written submission *
                                        </Typography>
                                    </Grid>
                                    <Grid container justifyContent="center" item xs={3}>
                                        <Typography variant="h4" className={classes.tableHeadingText}>
                                            Mapping submission
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} className={classes.draftContainer}>
                                    <Grid container alignItems="center" item xs={3} className={classes.firstColumn}>
                                        <Typography variant="h5" color="inherit" className={classes.lastEditedDate}>
                                            {submissionStore.getFormattedSubmissionDate(draftSubmission.lastModified || draftSubmission.createdDateTime)}
                                        </Typography>
                                    </Grid>
                                    <Grid container justifyContent="center" alignItems="center" item xs={3}>
                                        <span>
                                            {
                                                draftSubmission.hasWrittenSegment ?
                                                    <Button component={Link} to={`/writtensubmission/${draftSubmission.submissionId}?councilId=${reviewStore.reviewCouncilId}`} variant="text">
                                                        <Typography variant="subtitle2" color="primary" className={classnames(classes.linkText, classes.underline)}>
                                                            View and edit
                                                        </Typography>
                                                    </Button> :
                                                    <Button component={Link} to={`/writtensubmission/${draftSubmission.submissionId}?councilId=${reviewStore.reviewCouncilId}`} variant="text">
                                                        <Typography variant="subtitle2" color="primary" className={classnames(classes.linkText, classes.underline)}>
                                                            Add written
                                                        </Typography>
                                                    </Button>
                                            }
                                        </span>
                                    </Grid>
                                    <Grid container justifyContent="center" alignItems="center" item xs={3}>
                                        <span>
                                            {
                                                draftSubmission.hasMappingSegment && reviewStore.displayMappingTile ?
                                                    <Button onClick={() => { this.handleOpenMap(draftSubmission.submissionId); }} variant="text">
                                                        <Typography variant="subtitle2" color="primary" className={classnames(classes.linkText, classes.underline)}>
                                                            View and edit
                                                        </Typography>
                                                    </Button> :
                                                    (!draftSubmission.hasMappingSegment) && reviewStore.displayMappingTile ?
                                                    <Button onClick={() => {this.handleAddMapping(draftSubmission.submissionId);}} variant="text">
                                                        <Typography variant="subtitle2" color="primary" className={classnames(classes.linkText, classes.underline)}>
                                                            Add mapping
                                                        </Typography>
                                                    </Button> :
                                                     <Typography variant="h5" className={classes.subText}>N/A</Typography> 
                                            }
                                        </span>
                                    </Grid>
                                    <Grid container justifyContent="flex-end" alignItems="center" item xs={3}>
                                        <MoreInfoTooltip
                                            onClose={this.handleSubmitTooltipClose}
                                            onOpen={this.handleSubmitTooltipOpen(draftSubmission.hasWrittenSegment)}
                                            open={this.state.submitTooltipOpen}
                                            title="A written submission is required."
                                        >
                                            <span>
                                                <StyledPrimaryContainedButton
                                                    color="secondary"
                                                    variant="contained"
                                                    className={classnames(classes.submitButton)}
                                                    disabled={!draftSubmission.hasWrittenSegment}
                                                    onClick={this.handleSubmitDraft(draftSubmission.submissionId)}
                                                >
                                                    Submit
                                                </StyledPrimaryContainedButton>
                                            </span>
                                        </MoreInfoTooltip>
                                        <IconButton color="primary" onClick={this.handleDeleteDraftClick(draftSubmission.submissionId)}>
                                            <img src={DeleteIcon} alt="Delete" className={classes.deleteIcon} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Typography variant="subtitle2">* Indicates compulsory component</Typography>
                                </Grid>
                            </Hidden>
                            <Hidden mdUp>
                                <Grid container className={classes.draftContainer}>
                                    <Grid container item xs={12} className={classes.draftInfoContainerSmall}>
                                        <Grid container item xs={4} alignItems="center">
                                            <span>
                                                <Typography variant="subtitle2" className={classes.tableHeadingText}>
                                                    Last edited
                                                </Typography>
                                            </span>
                                        </Grid>
                                        <Grid container item xs={8} justifyContent="flex-end" alignItems="center">
                                            <span className={classes.lastEditedDate}>
                                                <Typography variant="subtitle2" color="inherit" className={classes.draftDetailsText}>
                                                    {submissionStore.getFormattedSubmissionDate(draftSubmission.lastModified || draftSubmission.createdDateTime)}
                                                </Typography>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid container item xs={6} alignItems="center">
                                            <span>
                                                <Typography variant="subtitle2" className={classes.tableHeadingText}>
                                                    Written submission *
                                                </Typography>
                                            </span>
                                        </Grid>
                                        <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                                            <span>
                                                {
                                                    draftSubmission.hasWrittenSegment ?
                                                        <Button component={Link} to={`/writtensubmission/${draftSubmission.submissionId}?councilId=${reviewStore.reviewCouncilId}`} variant="text">
                                                            <Typography variant="subtitle2" color="primary" className={classnames(classes.linkText, classes.underline)}>
                                                                View and edit
                                                            </Typography>
                                                        </Button> :
                                                        <Button component={Link} to={`/writtensubmission/${draftSubmission.submissionId}?councilId=${reviewStore.reviewCouncilId}`} variant="text">
                                                            <Typography variant="subtitle2" color="primary" className={classnames(classes.linkText, classes.underline)}>
                                                                Add written
                                                            </Typography>
                                                        </Button>
                                                }
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} className={classes.gutterBottom}>
                                            <Divider />
                                        </Grid>
                                        <Grid container item xs={6} alignItems="center" className={classes.gridCell}>
                                            <span>
                                                <Typography variant="subtitle2" className={classes.tableHeadingText}>
                                                    Mapping submission ~
                                                </Typography>
                                            </span>
                                        </Grid>
                                        <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                                            <span>
                                                {
                                                    draftSubmission.hasMappingSegment ?
                                                        <Button disabled variant="text" className={classes.draftDetailsButton}>
                                                            View / edit
                                                        </Button> :
                                                        <Button disabled variant="text" className={classes.draftDetailsButton}>
                                                            Add mapping
                                                        </Button>
                                                }
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} className={classes.gutterBottom}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} className={classes.gutterBottom}>
                                            <StyledPrimaryOutlinedButton
                                                color="secondary"
                                                variant="outlined"
                                                fullWidth
                                                className={classes.deleteDraftButton}
                                                onClick={this.handleDeleteDraftClick(draftSubmission.submissionId)}
                                            >
                                                Delete draft
                                            </StyledPrimaryOutlinedButton>
                                        </Grid>
                                        <Grid item xs={12} className={classes.gutterBottom}>
                                            <MoreInfoTooltip
                                                onClose={this.handleSubmitTooltipClose}
                                                onOpen={this.handleSubmitTooltipOpen(draftSubmission.hasWrittenSegment)}
                                                open={this.state.submitTooltipOpen}
                                                title="A written submission is required."
                                            >
                                                <span>
                                                    <StyledPrimaryContainedButton
                                                        fullWidth
                                                        color="secondary"
                                                        variant="contained"
                                                        className={classes.submitButton}
                                                        disabled={!draftSubmission.hasWrittenSegment}
                                                        onClick={this.handleSubmitDraft(draftSubmission.submissionId)}
                                                    >
                                                        Submit
                                                    </StyledPrimaryContainedButton>
                                                </span>
                                            </MoreInfoTooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Typography variant="subtitle2">* Indicates compulsory component</Typography>
                                    <Typography variant="subtitle2">~ Available on desktop computers only</Typography>
                                </Grid>
                            </Hidden>
                        </Grid>
                    }
                </Grid>
                <Dialog
                    open={this.state.deleteDraftDialogOpen}
                    onClose={this.handleDeleteDraftDialogClose}
                >
                    <DialogTitle>Would you like to delete the draft?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            The entire draft will be deleted. Do you wish to continue?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.deleteConfirmButton} onClick={this.handleDeleteDraftDialogClose} color="primary">
                            No
                        </Button>
                        <Button className={classes.deleteConfirmButton} onClick={this.handleDeleteDraft} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <SubmissionCompleteDialog />
            </div>
        );
    }

    handleSubmitTooltipClose = () => {
        this.setState({
            submitTooltipOpen: false
        });
    }

    handleSubmitTooltipOpen = (hasWrittenSegment) => () => {
        if (!hasWrittenSegment) {
            this.setState({
                submitTooltipOpen: true
            });
        }
    }

    handleOpenMap(submissionId) {
        this.props.reviewStore.startMapBasedSubmission(submissionId);
    }

    handleAddMapping(submissionId) {
        console.log(submissionId);
        this.props.reviewStore.setMappingOptionsDialog(submissionId);
    }

    handleDeleteDraftClick = (submissionId) => () => {
        this.props.msgBoxStore.show({
            content: Messages.DeleteDraftConfirmationMessage,
            header: 'Would you like to delete the draft?',
            size: 'xs',
            inputOptions: { type: 'NONE' },
            hideCancel: false,
            confirmButton: 'Yes',
            cancelButton: 'No'
        }).then(() => {
            this.props.submissionStore.deleteDraft(submissionId);
        }).catch((err) => {
            if (err) {
                console.log(err);
            }
        });
    }

    handleSubmitDraft = (submissionId) => () => {
        const { submissionStore } = this.props;

        submissionStore.submitDraft(submissionId);
    }
}

DraftSubmissions.propTypes = {
    classes: PropTypes.object.isRequired,
    stage: PropTypes.number.isRequired,
    reviewType: PropTypes.number.isRequired,
    submissionStore: PropTypes.object,
    msgBoxStore: PropTypes.object,
    reviewStore: PropTypes.object
}

export default withStyles(styles)(DraftSubmissions);
