import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, RadioGroup, Radio, FormControlLabel, FormControl, Typography, IconButton,  FormLabel } from '@material-ui/core';
import InfoIcon from '../../../../static/images/info_icon.svg'
import MoreInfoTooltip from './more-info-tooltip';

const styles = theme => ({
    preliminaryQuestionsText: {
        fontWeight: 600,
        color: theme.palette.primary.contrastText
    }
})

@inject('submissionStore', 'reviewStore')
@observer
class PreliminaryQuestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            speakToSubmissionTooltipOpen: false,
            informationTooltipOpen: false
        };
    }

    render() {
        const { classes, submissionStore } = this.props;
        return (
            <>
                <Grid>
                    <FormControl component="fieldset" margin="normal">
                        <FormLabel component="legend">
                            <Typography variant="subtitle2" className={classes.preliminaryQuestionsText}>
                                Do you want to speak at the public hearing for this stage of the Council review?
                            <MoreInfoTooltip
                                    onClose={() => this.handleSpeakToSubmissionTooltip(false)}
                                    onOpen={() => this.handleSpeakToSubmissionTooltip(true)}
                                    open={this.state.speakToSubmissionTooltipOpen}
                                    title="If you wish to speak at the public hearing you must indicate this here. If you select ‘Yes’ you will be contacted prior to the public hearing to organise a speaking time."
                                >
                                    <IconButton>
                                        <img src={InfoIcon} alt="info" />
                                    </IconButton>
                                </MoreInfoTooltip>
                            </Typography>
                        </FormLabel>
                        <RadioGroup
                            aria-label="Do you want to speak at the public hearing for this stage of the State redivision?"
                            name="wantsToSpeakToSubmission"
                            row={true}
                            value={submissionStore.wantsToSpeakToSubmission ? 'yes' : 'no'}
                            onChange={this.handleWantstoSpeakToSubmissionChange}>
                            <FormControlLabel
                                value="yes"
                                control={<Radio color="primary" />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value="no"
                                control={<Radio color="primary" />}
                                label="No"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </>
        );
    }

    handleInformationTooltip = isOpen => {
        this.setState({
            informationTooltipOpen: isOpen
        });
    }

    handleSpeakToSubmissionTooltip = isOpen => {
        this.setState({
            speakToSubmissionTooltipOpen: isOpen
        });
    }

    handleReadGuidelinesChange = event => {
        const { submissionStore } = this.props;

        submissionStore.setIsWrittenSubmissionFormDirty(true);
        submissionStore.hasReadGuidelines = event.target.value === 'yes';
    }

    handleWantstoSpeakToSubmissionChange = event => {
        const { submissionStore } = this.props;

        submissionStore.setIsWrittenSubmissionFormDirty(true);
        submissionStore.wantsToSpeakToSubmission = event.target.value === 'yes';
    }
}

PreliminaryQuestions.propTypes = {
    classes: PropTypes.object.isRequired,
    submissionStore: PropTypes.object
}

export default withStyles(styles)(PreliminaryQuestions);
