import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Enums } from '../../../enums'
import DraftSubmissions from '../submission/draft-submissions';
import CompletedSubmissions from '../submission/completed-submissions';
import { inject, observer } from 'mobx-react';

const styles = (theme) => ({
    titleContainer: {
        paddingTop: "30px",
        fontSize: '24px',
        lineHeight: '36px',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "20px"
        }
    },
    lastPositionStage: {
        order: 1
    },
});

@inject('reviewStore')
@observer
class SubmissionsSummaryStage1 extends React.Component {
    render() {
        const { classes, showDraftsStage1, showCompletedStage1, isProposedBoundariesPublished, reviewStore} = this.props;
        return (
            <Grid container className={isProposedBoundariesPublished ? classes.lastPositionStage : null}>
                {(showDraftsStage1 || showCompletedStage1) &&
                    <Grid item xs={12}>
                        <Typography variant="h2" color="secondary" className={classes.titleContainer}>Preliminary submissions</Typography>
                    </Grid>
                }
                {showDraftsStage1 &&
                    <Grid item xs={12}>
                        <DraftSubmissions stage={Enums.SubmissionStage.Preliminary} reviewType={reviewStore.reviewDetails.reviewType} />
                    </Grid>
                }
                {showCompletedStage1 &&
                    <Grid item xs={12}>
                        <CompletedSubmissions stage={Enums.SubmissionStage.Preliminary} reviewType={reviewStore.reviewDetails.reviewType} />
                    </Grid>
                }
            </Grid>
        );
    }
}

SubmissionsSummaryStage1.propTypes = {
    classes: PropTypes.object,
    reviewStore: PropTypes.object,
    showDraftsStage1: PropTypes.bool,
    showCompletedStage1: PropTypes.bool,
    isProposedBoundariesPublished: PropTypes.bool
};

export default withStyles(styles)(SubmissionsSummaryStage1);