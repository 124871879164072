import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { observable, action } from "mobx";
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import DragHandleIcon from '../../../static/images/dragHandle.svg';

const styles = theme => ({
    linkButton: {
        display: 'inline-block',
        textDecorationLine: 'none !important'
    },
    dragHandleIcon: {
        [theme.breakpoints.down('md')]: {
            marginTop: '9px'
        }
    },
    menuItemText: {
        color: theme.palette.secondary.charcoal,
        fontWeight: '600',
    },
    menu:{
        paddingTop : 'unset',
        paddingBottom : 'unset'
    }
});

@inject('authStore', 'msgBoxStore')
@observer
class HamburgerMenu extends React.Component {

    @observable anchorEl = null;

    @action
    setAnchorEl = (anchorEl) => {
        this.anchorEl = anchorEl;
    }

    render() {
        const { classes } = this.props;
        const open = Boolean(this.anchorEl);

        return (
            <React.Fragment>
                <Link component="button" className={classes.linkButton} onClick={this.handleClick}>
                    <img src={DragHandleIcon} alt="DragHandleIcon" className={classes.dragHandleIcon} />
                </Link>
                <Popover
                    id="hamburgerMenu"
                    open={open}
                    anchorEl={this.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}                   
                >
                    <MenuList className={classes.menu}>
                        <MenuItem onClick={this.handleSignInClick}>
                            <Typography
                                variant="body2"
                                className={classes.menuItemText}>
                                Sign in
                        </Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={this.handleSignUpClick}>
                            <Typography
                                variant="body2"
                                className={classes.menuItemText}>
                                Sign up
                        </Typography>
                        </MenuItem>
                    </MenuList>
                </Popover>
            </React.Fragment>
        );
    }

    handleClick = event => {
        this.setAnchorEl(event.currentTarget);
    };

    handleClose = () => {
        this.setAnchorEl(null);
    };

    handleSignInClick = () => {
        this.handleClose();
        this.props.authStore.authenticateUser();
    };

    handleSignUpClick = () => {
        let warningMessage = `When you create an account via the <b>Sign up</b> button you must use your real name and contact details.
                             <br/><br/> Anonymous submissions will not be considered. 
                             <br/><br/> At <b>Sign up</b>, you must include:
                             <br/>
                             <ul>
                                <li>your full name</li>
                                <li>a postal or residential address</li>
                                <li>a telephone number or email address</li>
                             </ul>
                             Information provided by submitters will be handled in accordance with the VEC's <a href="https://www.vec.vic.gov.au/privacy">Privacy Policy</a>.
                             <br/><br/> The name and locality/suburb of each submitter will be published on the VEC website alongside their submission.
                             <br/> For more information, please see the Submission Guide.`;
        this.props.msgBoxStore.show({
            content: warningMessage,
            header: 'Important',
            inputOptions: { type: 'NONE' },
            hideCancel: true,
            leftAlignContent: true,
            confirmButton: 'Continue',
            size: 'sm'
        }).then(() => {
            this.handleClose();
            this.props.authStore.register();
        });
    };

    handleHelpClick = () => {
        //TODO
    }
}

HamburgerMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    authStore: PropTypes.object,
    msgBoxStore: PropTypes.object,
}

export default withStyles(styles)(HamburgerMenu);

