import { action, computed, observable } from "mobx";
import * as Msal from 'msal';
import decode from 'jwt-decode';
import authService from '../services/authentication.service';
import profileService from '../services/profile.service';

export default class AuthenticationStore {

    @observable currentUserName;
    @observable accessTokenObservable;
    @observable userIsNew;
    @observable appAuthConfig;
    @observable signInAuthority
    @observable redirectUri;
    @observable processingLoadConfig = false;

    successLoginCallback = () => { };
    failureLoginCallback = () => { };

    constructor(sessionStore) {
        this.sessionStore = sessionStore;
        this.routerStore = sessionStore.routerStore;
        this.loadAuthDefaultConfig();
        profileService.setAuthStore(this.sessionStore.authStore);
        this.closePopUpBlockerEnabled();
    }

    @computed get isUserAuthenticated() {
        return this.currentUserName !== null && this.currentUserName !== undefined &&
            this.accessTokenObservable !== null && this.accessTokenObservable !== undefined && authService.isAuthenticated() !== null;
    }

    @computed get userProfile() {
        return authService.getUser();
    }

    @computed get signInPolicy() {
        return `https://${this.appAuthConfig.authority}/tfp/${this.appAuthConfig.tenant}/${this.appAuthConfig.signInPolicy}`;
    }

    @computed get signUpPolicy() {
        return `https://${this.appAuthConfig.authority}/tfp/${this.appAuthConfig.tenant}/${this.appAuthConfig.signUpPolicy}`;
    }

    @computed get resetPolicy() {
        return `https://${this.appAuthConfig.authority}/tfp/${this.appAuthConfig.tenant}/${this.appAuthConfig.resetPolicy}`;
    }

    @computed get b2cScopes() {
        if (this.appAuthConfig) {
            return {
                scopes: [`${this.appAuthConfig.applicationIdUri}/${this.appAuthConfig.scopeRead}`, `${this.appAuthConfig.applicationIdUri}/${this.appAuthConfig.scopeWrite}`]
            };
        }
        return [];
    }

    @computed get claims() {
        if (this.accessTokenObservable) {
            const rawClaims = decode(this.accessTokenObservable);

            return {
                firstName: rawClaims.given_name,
                lastName: rawClaims.family_name,
                fullName: rawClaims.name,
                email: rawClaims.emails[0],
                objectId: rawClaims.oid,
            }
        }
        return {};
    }

    authenticateUser() {
        if (this.isUserAuthenticated) {
            return Promise.resolve();
        } else {
            return this.promptLogin();
        }
    }

    @action promptLogin = () => {
        this.sessionStore.rootStore.setBusy(true);
        return authService.loginPopup(this.signInPolicy)
            .then(() => {
                this.acquireAccessToken();
                this.sessionStore.rootStore.setBusy(false);
            }
                , (error) => {
                    this.sessionStore.rootStore.setBusy(false);

                    if (error.toString().indexOf("AADB2C90118") > -1) {
                        this.resetPassword();
                    }
                    else {
                        if (error.toString().indexOf("Error opening popup window") > -1) {
                            this.sessionStore.msgBoxStore.show({
                                content: "This Public Submission Tool requires Pop-ups to be enabled. Please go to your browser settings and enable pop-ups.",
                                header: 'Pop-ups disabled',
                                size: 'xs',
                                inputOptions: { type: 'NONE' },
                                hideCancel: true,
                                confirmButton: 'OK'
                            }).then(() => { })
                            .catch((err) => {
                                if (err) {
                                    console.log(err);
                                }
                            });
                            return Promise.reject(error);
                        } 
                        else {
                            console.log("Login failed - " + error);
                            this.failureLoginCallback();
                        }
                    }
                });
    }

    @action initializeProfile = () => {
        this.initializeProfileFromClaims(this.claims);
        this.validateProfile(this.claims.objectId);
        this.currentUserName = this.claims.fullName;

    }

    @action requestAccessToken = () => {
        if (authService.isAuthenticated() && !this.accessTokenObservable) {
            this.acquireAccessToken();
        }
        else {
            this.processingLoadConfig = false;
        }
    }

    @action renewAccessToken = () => {
        if (authService.isAuthenticated()) {
            return this.acquireAccessToken(false);
        }   
        else {
            return Promise.resolve();
        }
    }

    @action loadAuthDefaultConfig = () => {
        const { rootStore } = this.sessionStore;
        this.processingLoadConfig = true;
        if (!this.appAuthConfig) {
            rootStore.setBusy(true);
            authService.loadAuthDefaultConfig().then((config) => {
                var signinPolicy = `https://${config.authority}/tfp/${config.tenant}/${config.signInPolicy}`
                this.redirectUri = config.replyUrl;
                const msalConfig = {
                    auth: {
                        validateAuthority: false,
                        clientId: config.clientId,
                        authority: signinPolicy,
                        redirectUri: this.redirectUri,
                        postLogoutRedirectUri: config.replyUrl,
                    },
                    cache: {
                        cacheLocation: "localStorage"
                    }
                };

                window.msalAuthApp = new Msal.UserAgentApplication(msalConfig);

                window.msalAuthApp.handleRedirectCallback(authService.authCallback);
                rootStore.setBusy(false);
                this.appAuthConfig = config;
                this.requestAccessToken();
            }).catch((err) => {
                console.error(err);
                rootStore.setBusy(false);
            });
        }
    }

    @action acquireAccessToken = (loadProfile = true) => {
        this.sessionStore.rootStore.setBusy(true);
        return window.msalAuthApp.acquireTokenSilent(this.b2cScopes).then(
            (access_token) => {
                this.accessTokenObservable = access_token.accessToken;
                window.accessToken = access_token.accessToken;

                if(loadProfile) {
                    this.initializeProfile();
                }

                this.sessionStore.rootStore.setBusy(false);
                this.processingLoadConfig = false;
            },
            (err) => {
                console.log(err);
                this.sessionStore.rootStore.setBusy(false);
                if (err.toString().indexOf("consent_required") !== -1 ||
                    err.toString().indexOf("interaction_required") !== -1 ||
                    err.toString().indexOf("login_required") !== -1 ||
                    err.toString().indexOf("timeout")) {
                    this.sessionStore.rootStore.setBusy(false);

                    if (!this.processingLoadConfig) {
                        this.acquireTokenPopup();
                    }
                }
                this.processingLoadConfig = false;
            }
        );
    }

    @action acquireTokenPopup = () => {
        this.sessionStore.rootStore.setBusy(true);
        window.msalAuthApp.authority = this.signInPolicy;
        window.msalAuthApp
            .acquireTokenPopup(this.b2cScopes)
            .then(
                (access_token) => {
                    this.accessTokenObservable = access_token.accessToken;
                    window.accessToken = access_token.accessToken;
                    this.initializeProfile();
                    this.sessionStore.rootStore.setBusy(false);
                },
                (err) => {
                    this.sessionStore.rootStore.setBusy(false);
                    console.log("Failure acquiring token: " + err);
                    this.accessTokenObservable = null;
                }
            );
    }

    @action validateProfile = (profileId) => {
        this.sessionStore.rootStore.setBusy(true);
        profileService.doesProfileExist(profileId).then((response) => {
            if (response === false) {
                this.sessionStore.routerStore.history.push('/profile');
            }
            else {
                // Load user's profile and submissions after successful login
                this.sessionStore.profileStore.getProfile();
                this.successLoginCallback();
            }

            this.sessionStore.submissionStore.getSubmissions();
            this.sessionStore.rootStore.setBusy(false);
        }, (err) => {
            console.log("Error checking profile " + err);
            this.sessionStore.rootStore.setBusy(false);
        });
    }

    @action initializeProfileFromClaims = (claims) => {
        this.sessionStore.profileStore.setProfileId(claims.objectId),
            this.sessionStore.profileStore.setFirstName(claims.firstName),
            this.sessionStore.profileStore.setLastName(claims.lastName),
            this.sessionStore.profileStore.setEmailAddress(claims.email)
    }

    @action register = () => {
        this.sessionStore.rootStore.setBusy(true);
        authService.loginPopup(this.signUpPolicy)
            .then(() => {
                this.sessionStore.rootStore.setBusy(false);
                this.acquireAccessToken();
            }
                , (error) => {
                    if (error.toString().indexOf("Error opening popup window") > -1) {
                        this.sessionStore.msgBoxStore.show({
                            content: "This Public Submission Tool requires Pop-ups to be enabled. Please go to your browser settings and enable pop-ups.",
                            header: 'Pop-ups disabled',
                            size: 'xs',
                            inputOptions: { type: 'NONE' },
                            hideCancel: true,
                            confirmButton: 'OK'
                        }).then(() => { })
                        .catch((err) => {
                            if (err) {
                                console.log(err);
                            }
                        });
                    } 
                    else {
                        console.log("Login failed - " + error);
                        this.failureLoginCallback();
                    }
                   
                    this.sessionStore.rootStore.setBusy(false);
                });
    }

    @action resetPassword = () => {
        this.sessionStore.rootStore.setBusy(true);
        authService.loginPopup(this.resetPolicy)
            .then(() => {
                this.sessionStore.rootStore.setBusy(false);
                this.acquireTokenPopup();
            }
                , (error) => {
                    console.log("Login failed - " + error);
                    this.failureLoginCallback();
                    this.sessionStore.rootStore.setBusy(false);
                });
    }

    @action logout = () => {
        const { reviewStore } = this.sessionStore;
        authService.logout(reviewStore.reviewCouncilId);
        this.currentUserName == null;
    }

    @action setFailureLoginCallback = (failureLoginCallback) => {
        this.failureLoginCallback = failureLoginCallback;
    }

    @action checkUserAuthenticated = (callBackAuthenticated) => {
        let deferred = new Promise((resolve, reject) => {
            if (this.processingLoadConfig) {
                reject('Processing auth configuration');
            }
            else {
                if (this.isUserAuthenticated && !!callBackAuthenticated) {
                    callBackAuthenticated();
                }
                resolve(this.isUserAuthenticated);
            }
        });

        return deferred;
    }

    closePopUpBlockerEnabled() {
        if (window.location.hash.toString().indexOf("id_token") > -1 && window.location.hash.toString().indexOf("access_token") === -1) {
            setTimeout(function(){ 
                    open(window.location, '_self').close();
                    window.close();
            }, 3000);
        }
    }
}

