import RootStore from './root.store';
import CouncilStore from './council.store';
import SubmissionStore from './submission.store';
import ConfigurationStore from './configuration.store';
import ModelingMapStore from './modeling-map.store';
import ProfileStore from './profile.store';
import AuthenticationStore from './authentication.store';
import RouterStore from './router.store';
import MsgBoxStore from './msg-box.store';
import reviewStore from './review.store';

export default class SessionStore {
    constructor() {
        this.msgBoxStore = new MsgBoxStore();
        this.routerStore = new RouterStore(this);
        this.rootStore = new RootStore(this);
        this.authStore = new AuthenticationStore(this);
        this.councilStore = new CouncilStore(this);
        this.submissionStore = new SubmissionStore(this);
        this.configurationStore = new ConfigurationStore(this);
        this.modelingMapStore = new ModelingMapStore(this);
        this.profileStore = new ProfileStore(this);
        this.reviewStore = new reviewStore(this);
    }
}
