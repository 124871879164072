import BaseService from './base.service.js'
import { Tools } from '../tools';

class SubmissionService extends BaseService {

    getSubmissions() {
        let url = '/api/submissions';
        return this.fetch(url).then(Tools.getResponseJson);
    }

    saveSubmission(submissionForm) {
        const url = `/api/submissions`;

        return this.fetch(url, {
            method: 'post',
            body: submissionForm
        }).then(Tools.getResponseJson);
    }

    getDraftDetails(submissionId, loadMapSubmission) {
        const url = `/api/submissions/drafts/${submissionId}/?loadMap=${loadMapSubmission}`;
        return this.fetch(url).then(Tools.getResponseJson);
    }

    deleteDraft(submissionId) {
        const url = `/api/submissions/drafts/${submissionId}`;

        return this.fetch(url, {
            method: 'delete'
        }).then(Tools.getResponseJson);
    }

    submitDraft(submissionId) {
        const url = `/api/submissions/drafts/${submissionId}`;

        return this.fetch(url, {
            method: 'post'
        }).then(Tools.getResponseJson);
    }
}

const submissionService = new SubmissionService();

export default submissionService;
